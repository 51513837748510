import styles from './modalClue.module.scss';
import { CloseCircleSolid } from '../../../Login/CloseModal';
import { clues } from './clues';


export default function ModalClue({ lockId, isOpen, setIsOpen }) {
	function handleBackdropClick(e) {
		if (e.target === e.currentTarget) {
			setIsOpen(false);
		}
	}
	function getClue(lockId){
		if(!clues[lockId]) return 'Cadeado sem dica'
		return clues[lockId]
	}
	if(!isOpen) return
	return (
		<div onClick={handleBackdropClick} className={styles.backdrop}>
			<div className={`${styles.modal}`}>
				<CloseCircleSolid
					onClick={() => setIsOpen(false)}
					className={styles.closeModal}
				/>

				<div className={styles.body}>
					<span>{getClue(lockId)}</span>
				</div>
			</div>
		</div>
	);
}
