/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import EscapeModal from './EscapeModal/EscapeModal';
import ModalClue from './ModalClue/ModalClue';
import styles from './escapeLive.module.scss';
import { useQuery } from 'src/hooks/queryClient';
import { useParams } from 'react-router-dom';
import OpenLock from './interesting_points/Open';
import ClosedLock from './interesting_points/Lock';
import GenericModal from 'src/components/Generics/Modal/Modal';
import ModalContentEscapeLive from './EscapeModal/ModalContent/ModalContent';
import MagGlass from './interesting_points/Mag_glass';
import ClockComponent from './Clock/Clock';
import ReactLoading from 'react-loading';
import { escapeLive1 } from './Escapes/Escape1/Escape1';
import { escapeLive2 } from './Escapes/Escape2/Escape2';
import { escapeLive3 } from './Escapes/Escape3/Escape3';
import { escapeLive4 } from './Escapes/Escape4/Escape4';
import { escapeLive5 } from './Escapes/Escape5/Escape5';
import { escapeLive6 } from './Escapes/Escape6/Escape6'
import { escapeLive7 } from './Escapes/Escape7/Escape7'
import { escapeLive8 } from './Escapes/Escape8/Escape8'
import { escapeLive9 } from './Escapes/Escape9/Escape9'
import { escapeLive10 } from './Escapes/Escape10/Escape10'
import { escapeLive11 } from './Escapes/Escape11/Escape11'
import { escapeLive12 } from './Escapes/Escape12/Escape12'
import { escapeLive13 } from './Escapes/Escape13/Escape13';
import { escapeLive14 } from './Escapes/Escape14/Escape14';

const escapes = {
	
	/// semana 1
	1: escapeLive1,
	3: escapeLive2,
	// semana 3
	8: escapeLive3,
	10: escapeLive4,
	//semana 5
	15: escapeLive5,
	17: escapeLive6,
	// semnana 7
	22: escapeLive7,
	24: escapeLive8,
	// semana 9
	29: escapeLive9,
	31: escapeLive10,
	// semana 11
	36: escapeLive11,
	38: escapeLive12,
	// semana 13
	43: escapeLive13,
	45: escapeLive14

};

export default function EscapeLive({ setPage, missionId, userReport }) {
	const [openModal, setOpenModal] = useState(false);
	const token = localStorage.getItem('SUT');
	const { playerId } = useParams();
	const [openGenericModal, setOpenGenericModal] = useState(false);
	const [unlockByTime, setUnlockByTime] = useState(false);
	const [isOpenClue, setIsOpenClue] = useState(false)
	const escape = escapes[missionId];
	const [content, setContent] = useState({
		type: null,
		bodyContent: null,
	});
	const [lockId, setLockId] = useState('')
	const [enableEscapeTimeQuery, setEnableEscapeTimeQuery] = useState(false);

	useEffect(() => {
		if (token) {
			const timer = setTimeout(() => {
				setEnableEscapeTimeQuery(true);
			}, 500);
			return () => clearTimeout(timer);
		}
	}, []);

	const { data: escapeLiveTimer } = useQuery({
		url: `/getEscapeTimeStarted/${playerId}/${missionId}`,
		key: `escapeLiveTime${missionId}`,
		enabled: enableEscapeTimeQuery,
		token: token,
	});
 
	const { data: escapeLiveReport, status } = useQuery({
		url: `/getEscapeReport/${playerId}`,
		key: `escapeLiveReport${missionId}`,
		enabled: !!token,
		token: token,
	});

	useEffect(() => {
		const redirect = escape.redirectTo;

		if (userReport.missions.includes(missionId)) {
			setTimeout(() => {
				setPage(redirect.type, redirect.missionId);
			}, 0);
		}
	}, []);

	function openModalFn(content) {
		setContent(content);
		setOpenModal(true);
	}

	return status === 'success' ? (
		<div className={styles.scenario}>
			<ModalClue isOpen={isOpenClue} setIsOpen={setIsOpenClue} lockId={lockId}/>
			<EscapeModal
				setPage={setPage}
				content={content}
				isOpen={openModal}
				setIsOpen={setOpenModal}
				setOpenGenericModal={setOpenGenericModal}
				setUnlockByTime={setUnlockByTime}
				missionId={missionId}
				setLockId={setLockId}
				setIsOpenClue={setIsOpenClue}
			/>
			<GenericModal
				closeBtnDisabled
				isOpen={openGenericModal}
				setIsOpen={setOpenGenericModal}
				// missionId 2
				onClick={() =>
					setPage(escape.redirectTo.type, escape.redirectTo.missionId)
				}
				content={{
					modalContent: (
						<ModalContentEscapeLive unlockByTime={unlockByTime} />
					),
				}}
			/>

			<div className={styles.gameWrapper}>
				{escape.interestingPoints.map((lock, i) => {
					if (lock.type === 'lock') {
						return (
							<div key={`${lock.lockId}_${i}`}>
								{escapeLiveReport &&
								escapeLiveReport.locks.includes(lock.lockId) ? (
									<OpenLock
										onClick={() =>
											openModalFn(lock.onClickOpen)
										}
										className={lock.className}
									/>
								) : (
									<ClosedLock
										className={lock.className}
										onClick={() =>
											openModalFn(lock.onClick)
										}
									/>
								)}
							</div>
						);
					}
					if (lock.type === 'image') {
						return (
							<div key={`${lock.lockId}_${i}`}>
								{escapeLiveReport && (
									<MagGlass
										className={lock.className}
										onClick={() =>
											openModalFn(lock.onClick)
										}
									/>
								)}
							</div>
						);
					}
					return null;
				})}
				<img src={escape.bgImage} alt="background" />

				<ClockComponent
					escapeLiveId={missionId}
					setOpenGenericModal={setOpenGenericModal}
					setUnlockByTime={setUnlockByTime}
					escapeLiveTimer={escapeLiveTimer}
				/>
			</div>
		</div>
	) : (
		<div className={styles.loading}>
			<ReactLoading type={'spin'} height={'100%'} className={styles.spin} color={'white'} />
		</div>
	);
}
