
export const quiz6= {
	missionId: 18,
	img: '/sicred/city2.png',
	auxImg: '/quiz/quiz01_aux.png',
	redirectTo: {
		type: 'video',
		missionId: 19
	},
	questionTxt:
		'Você é responsável pela análise de crédito na cooperativa e encontra um pedido de empréstimo de um candidato político. O candidato te procura e sugere que, se o empréstimo for aprovado rapidamente e com condições favoráveis, o Sicredi poderá ser beneficiado com políticas governamentais mais favoráveis no futuro. O que você faz?',
	alternatives: [
		{
			id: 1,
			text: 'A - Rejeita a proposta de favorecimento e comunica a área de controles internos/diretoria.',
			next: null,
			redirectTo: {
				type: 'video',
				missionId: 5,
			},
			score: 100,
			correct: true
		},
		{
			id: 2,
			text: 'B - Prosseguir com a análise e aprovação do empréstimo sem relatar a proposta de favorecimento, fingindo que a abordagem do candidato não ocorreu.',
			next: null,
			redirectTo: {
				type: 'video',
				missionId: 5,
			},
			score: 0,
			correct: false

		},
		{
			id: 3,
			text: 'C - Executar a análise de crédito normalmente sem beneficiar o candidato e ignorar as condições sugeridas pelo candidato.',
			next: null,
			redirectTo: {
				type: 'video',
				missionId: 5,
			},
			score: 0,
			correct: false

		},
		{
			id: 4,
			text: 'D - Concordar com a proposta do candidato, acelerando a aprovação do empréstimo, mas nao aceitando sobre nenhuma condição comentada pelo candidato.',
			next: null,
			redirectTo: {
				type: 'video',
				missionId: 5,
			},
			score: 0,
			correct: false

		},
	],
};
