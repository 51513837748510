
export const quiz7= {
	missionId: 23,
	img: '/sicred/city2.png',
	auxImg: '/quiz/quiz01_aux.png',
	redirectTo: {
		type: 'game',
		missionId: 24
	},
	questionTxt:
		'Durante uma pausa para o café, João e Maria, colegas de trabalho do Sicredi, começam a discutir sobre as próximas eleições municipais. A conversa começa a ficar acalorada, e outros colegas começam a se juntar. Você, uma pessoa colaboradora do Sicredi, percebe a situação e lembra-se do código de conduta do Sicredi. Qual a sua atitude?',
	alternatives: [
		{
			id: 1,
			text: 'A - Fingir não perceber a discussão acalorada e se afastar do local para nao fazer parte da situação, pois você se lembra da recomendação do código de conduta.',
			next: null,
			redirectTo: {
				type: 'video',
				missionId: 5,
			},
			score: 0,
			correct: false
		},
		{
			id: 2,
			text: 'B -  Intervém o conflito conversando diretamente com os colegas João e Maria para nao continuar com a discussão, lembrando-os do código de conduta do Sicredi.',
			next: null,
			redirectTo: {
				type: 'video',
				missionId: 5,
			},
			score: 0,
			correct: false

		},
		{
			id: 3,
			text: 'C - Intervém suavemente, sugerindo que seja melhor evitar falar sobre posições político-partidárias dentro dos ambientes e horários de trabalho, mudando o assunto para um tema relacionado ao projeto atual da equipe.',
			next: null,
			redirectTo: {
				type: 'video',
				missionId: 5,
			},
			score: 100,
			correct: true

		},
		{
			id: 4,
			text: 'D -  Interfere na conversa tentando amenizar a discussão, lembrando os colega de conversar com respeito entre eles.',
			next: null,
			redirectTo: {
				type: 'video',
				missionId: 5,
			},
			score: 0,
			correct: false

		},
	],
};
