export default function SupportLogin(props) {
	return (
		<svg
			{...props}
			width="40px"
			height="40px"
			viewBox="0 0 47 50"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M30.0822 45.8008C30.0822 45.8008 39.4832 45.8008 39.4832 34.8313H42.9319C44.49 34.8313 45.7522 33.569 45.7522 32.011V23.5501C45.7522 21.9921 44.49 20.7299 42.9319 20.7299H39.4832V17.598C39.4832 8.947 33.2511 1.92798 24.6001 1.92798H23.0315C14.3753 1.92798 8.14845 8.94171 8.14845 17.598V20.7299H4.69968C3.14166 20.7299 1.87939 21.9921 1.87939 23.5501V32.011C1.87939 33.569 3.14166 34.8313 4.69968 34.8313H6.57986"
				stroke="black"
				strokeWidth="2"
				strokeLinecap="round"
			/>
			<path
				d="M30.0822 45.8008C30.0822 44.0685 28.6773 42.6689 26.9503 42.6689H23.8184C22.0861 42.6689 20.6865 44.0738 20.6865 45.8008C20.6865 47.5279 22.0914 48.9327 23.8184 48.9327H26.9503C28.6826 48.9327 30.0822 47.5279 30.0822 45.8008Z"
				stroke="white"
				strokeWidth="2"
				strokeLinecap="round"
			/>
		</svg>
	);
}
