/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react';
import styles from './clock.module.scss';
import { AnimatedClock } from './animatedClock';
import { getApiClient } from 'src/services/axios';
import { useParams } from 'react-router-dom';
import moment from 'moment-timezone'

export default function ClockComponent({
	escapeLiveTimer,
	escapeLiveId,
	setUnlockByTime,
	setOpenGenericModal,
}) {
	const [timerOpen, setTimerOpen] = useState(true);
	const [timer, setTimer] = useState('');
	const { playerId } = useParams();

	
	async function handleEndTimes() {
		const token = localStorage.getItem('SUT');
		const endedTime = moment.tz('America/Sao_Paulo').toDate();
		await getApiClient(token).post(`/updateEscapeReport/${playerId}`, {
			escapeLiveIds: [escapeLiveId],
			locks: [],
			startedTimes: [],
			endedTimes: [
				{
					timeEnded: endedTime,
					escapeLiveId: escapeLiveId,
					escape: false
				},
			],
		});
	}

	useEffect(() => {
		let intervalId;

		if (escapeLiveTimer) {
			const updateTimer = () => {
				const distance = escapeLiveTimer; // O tempo restante em milissegundos

				if (distance <= 0) {
					handleEndTimes();
					clearInterval(intervalId);
					setUnlockByTime(true);
					setOpenGenericModal(true);
				} else {
					const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
					const seconds = Math.floor((distance % (1000 * 60)) / 1000);
					setTimer(`${minutes}m ${seconds}s`);
				}
			};

			updateTimer();
			intervalId = setInterval(() => {
				escapeLiveTimer -= 1000; // Decrementa 1 segundo a cada intervalo
				updateTimer();
			}, 1000);

			return () => clearInterval(intervalId);
		}
	}, [escapeLiveTimer]);

	return (
		timer !== '' &&
		<div
			className={`${styles.timer} ${styles.open}`}
			onMouseEnter={() => setTimerOpen(true)}
			onMouseLeave={() => setTimerOpen(false)}
		>
			<span>
				<AnimatedClock className={styles.clock} />
			</span>
			<span>{timer}</span>
		</div>
	);
}
