import { useState } from 'react';
import Login from 'src/components/Login/Login';
import Signup from 'src/components/Signup/Signup';
import styles from './index.module.scss';
import SuportModal from 'src/components/SupportModal/SuportModal'
import SupportLogin from './SuportSVG';

export default function Index() {
	const [loginOpen, setloginOpen] = useState(true);
	const [openSuportModal, setOpenSuportModal] = useState(false)
	return (
		<>
			<SuportModal isOpen={openSuportModal} setIsOpen={setOpenSuportModal} />
			{loginOpen ?  <Login /> : <Signup  /> }
			<button
				onClick={() => setloginOpen((prev)=>!prev)}
				className={`${styles.button} ${ !loginOpen && styles.signup}`}
			>
				{!loginOpen ? 'Já possui cadastro?' : 'Ainda não possui cadastro?'}
			</button>
			<div onClick={()=>setOpenSuportModal(true)} className={styles.suport}>
				<SupportLogin/>
			</div>
		</>
	);
}
