import styles from './modalContent.module.scss';

export default function ModalContentSelectOptions({options, corrects}) {
	function getCorrectOptions(){
		if(options){
			let correct = 0
			for(const option of options){
				if(option.score > 0){
					correct = correct + 1
				}
			}
			return correct
		}
	}
	return (
		<div className={styles.modalContent}>
			<span>Você acertou {getCorrectOptions()}/{corrects}</span>
			<span>
				Muito bem, agora você concluiu o treinamento. Como última etapa
				vamos ver uma mensagem sobre o canal de denúncias, e depois você
				poderá receber sua recompensa. Obrigado pela sua participação.
			</span>
		</div>
	);
}
