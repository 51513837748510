
export const quiz3= {
	missionId: 9,
	img: '/sicred/city2.png',
	auxImg: '/quiz/quiz01_aux.png',
	redirectTo: {
		type: 'game',
		missionId: 10
	},
	questionTxt:
		'Durante o período eleitoral, você, pessoa colaboradora do Sicredi, descobre que um dos associados está concorrendo a um cargo político contrário à sua posição partidária. Você tem acesso às informações financeiras detalhadas do associado e percebe que poderia usar esses dados para criar uma narrativa negativa que poderia prejudicar a campanha do associado. No entanto, o que você deve fazer?',
	alternatives: [
		{
			id: 1,
			text: 'A - A critério de curiosidade, ver todas as informações financeiras sobre o candidato, mas apenas transmitir de forma anônima ou entre amigos o que descobri.',
			next: null,
			redirectTo: {
				type: 'video',
				missionId: 5,
			},
			score: 0,
			correct: false
		},
		{
			id: 2,
			text: 'B - Não posso utilizar informações internas (financeiras ou de cadastro) de quaisquer associados fora do ambiente da Cooperativa, e muito menos para prejudicá-los, sob pena de responder pelo crime de violação de sigilo bancário.',
			next: null,
			redirectTo: {
				type: 'video',
				missionId: 5,
			},
			score: 100,
			correct: true

		},
		{
			id: 3,
			text: 'C - Fornecer as informações financeiras do associado a um candidato político que você apoia, ajudando a criar uma campanha difamatória contra o adversário.',
			next: null,
			redirectTo: {
				type: 'video',
				missionId: 5,
			},
			score: 0,
			correct: false

		},
		{
			id: 4,
			text: 'D -  Usar os dados financeiros detalhados do associado para criar e divulgar uma narrativa negativa que prejudique a campanha política dele, comprometendo sua reputação.',
			next: null,
			redirectTo: {
				type: 'video',
				missionId: 5,
			},
			score: 0,
			correct: false

		},
	],
};
