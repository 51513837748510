/* eslint-disable jsx-a11y/alt-text */
import React, { useRef } from 'react';
import styles from './stepper.module.scss';
import { isMobile } from 'react-device-detect';
import { Check } from './Check';
import showToast from 'src/components/utils/toast';
import { ClosedLock, OpenedLock } from './sicredLocks';
import HomeSVG from 'src/components/HomeBtn/homeSvg'

export default function CustomStepper({
	steps,
	activeStep,
	userReport,
	preGameContent,
	setPreGameContent,
}) {
	const scrollRef = useRef(null);

	const handleScroll = (event) => {
		
		if (scrollRef.current) {
			scrollRef.current.scrollLeft += event.deltaY;
		}
	};

	function userFinishedMission(missionId) {
		return userReport.missions.includes(missionId);
	}

	function handleClickNotShow() {
		showToast({
			text: 'Etapa ainda não foi liberada',
			type: 'error',
		});
	}

	function preGameSetter(step) {
		if (userFinishedMission(step.finishedMissionId)) {
			showToast({
				text: 'Missão já concluída',
				type: 'success',
			});
			return;
		}
		setPreGameContent(step);
	}
	return (
		<div
			className={`${styles.wrapper}`}
		>
			<div
				className={styles.homeBtn}
				onClick={() => setPreGameContent(null)}
			>
				<HomeSVG/>
			</div>
			<div
				ref={scrollRef}
				onWheel={handleScroll}
				className={`${styles.stepper} ${isMobile && styles.mobile}`}
			>
				{steps.map((step, i) => {
					if (step.hidden) return null;

					return (
						<div
							onClick={() =>
								step.unlocked
									? preGameSetter(step)
									: handleClickNotShow()
							}
							className={`
							${styles.step} 
							${
								step.unlocked &&
								!userFinishedMission(step.finishedMissionId)
									? styles.show
									: ''
							} 
							${activeStep === i && styles.active}
							`}
							key={`step_${i}`}
						>
							<div className={styles.header}>
								<div className={styles.checkbox}>
									{step.unlocked ? (
										userFinishedMission(
											step.finishedMissionId
										) ? (
											<Check />
										) : (
											<OpenedLock />
										)
									) : (
										<ClosedLock />
									)}
								</div>
								<span>{step.label}</span>
							</div>
							<div className={styles.hexagon}>
								{step.unlocked ? (
									userFinishedMission(
										step.finishedMissionId
									) ? (
										<div className={styles.finished}>
											<Check
												className={styles.checkImg}
											/>
											<img
												src={step.img}
												className={styles.finishedImg}
												alt={step.label}
											/>
										</div>
									) : (
										<img
											className={styles.openImg}
											src={step.img}
											alt={step.label}
										/>
									)
								) : (
									<ClosedLock />
								)}
							</div>
						</div>
					);
				})}
			</div>
		</div>
	);
}
