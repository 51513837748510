import styles from './escape14.module.scss';

export const escapeLive14 = {
	missionId: 45,
	bgImage: '/escape/scenario14/bg.png',
	interestingPoints: [
		{
			type: 'image',
			onClick: {
				type: 'image',
				img: ['/escape/scenario14/escape1_1.png','/escape/scenario14/escape1_2.png' ],
			},
			className: `${styles.scenario_image_1} ${styles.locks}`,
		},
		{
			type: 'image',
			onClick: {
				type: 'image',
				img: '/escape/scenario14/escape2.png',
			},
			className: `${styles.scenario_image_2} ${styles.locks}`,
		},
		{
			lockId: 'scenario_14_lock_1',
			type: 'lock',
			onClick: {
				lockId: 'scenario_14_lock_1',
				redirectTo: null,
				type: 'lock',
				password: 'AD',
				inputType: 'text',
				bodyContent: {
					img: '/escape/scenario14/lock1.png',
				},
			},
			className: `${styles.scenario_lock_1} ${styles.locks}`,
			onClickOpen: {
				type: 'image',
				img: '/escape/scenario14/reward_1.png',
			},
		},
		{
			lockId: 'scenario_14_lock_2',
			type: 'lock',

			onClick: {
				lockId: 'scenario_14_lock_2',
				type: 'lock',
				missionId: 29,
				redirectTo: {
					type: 'game',
					missionId: 30,
				},
				password: 'HUSW',
				inputType: 'text',
				bodyContent: {
					img: '/escape/scenario14/lock2.png',
				},
			},
			className: `${styles.scenario_lock_2} ${styles.locks}`,
		},
	],
	redirectTo: {
		type: 'game',
		missionId: 46,
	},
};
