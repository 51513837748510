
export const quiz5= {
	missionId: 16,
	img: '/sicred/city2.png',
	auxImg: '/quiz/quiz01_aux.png',
	redirectTo: {
		type: 'game',
		missionId: 17
	},
	questionTxt:
		'Você, pessoa colaboradora do Sicredi é abordado por um candidato político que oferece uma doação significativa para um projeto comunitário patrocinado pelo Sicredi. Em troca, o candidato pede apoio publicamente em sua campanha. O que você faz?',
	alternatives: [
		{
			id: 1,
			text: 'A - Aceitar a doação de maneira discreta, sem mencionar o apoio público, pois o valor arrecadado vai ajudar significantemente o projeto comunitário.',
			next: null,
			redirectTo: {
				type: 'video',
				missionId: 5,
			},
			score: 0,
			correct: false
		},
		{
			id: 2,
			text: 'B - Não aceita e comenta que qualquer doação deve ser feito diretamente para o Sicredi, para que ele distribua para os projetos comunitários.',
			next: null,
			redirectTo: {
				type: 'video',
				missionId: 5,
			},
			score: 0,
			correct: false

		},
		{
			id: 3,
			text: 'C - Não aceita e comenta que o Sicredi adota a neutralidade politica, razão pela qual não pode favorecer de qualquer forma candidatos, partidos e/ou colaboradores envolvidos em campanhas ou cargos político-partidários.',
			next: null,
			redirectTo: {
				type: 'video',
				missionId: 5,
			},
			score: 100,
			correct: true

		},
		{
			id: 4,
			text: 'D -  Não aceita, mas entra em contato com o seu supervisor alguma maneira de que essa doação seja aprovada e direcionada para o projeto comunitário.',
			next: null,
			redirectTo: {
				type: 'video',
				missionId: 5,
			},
			score: 0,
			correct: false

		},
	],
};
