/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/alt-text */
import styles from './header.module.scss';
import Logo from './logo';
import ShieldLogo from './ShieldLogo';
import { isMobile } from 'react-device-detect';
import showToast from 'src/components/utils/toast';
import avatar1 from '../../../images/avatars/avatar_01.jpg';
import avatar2 from '../../../images/avatars/avatar_02.jpg';
import avatar3 from '../../../images/avatars/avatar_03.jpg';
import avatar4 from '../../../images/avatars/avatar_04.jpg';
import avatar5 from '../../../images/avatars/avatar_05.jpg';
import avatar6 from '../../../images/avatars/avatar_06.jpg';
import { LogOff } from './loggofIcon';
import Support from './Support';
import SuportModal from 'src/components/SupportModal/SuportModal';
import { useState } from 'react';
import ReportLogo from './reportLogo';
import { adminList } from 'src/components/reports/ReportPlayers';

function getFinishedMissions(steps, userMissions) {
	let finishedMissionsCount = 0;

	userMissions.forEach((missionId) => {
		const step = steps.find((step) => step.missionId === missionId);
		if (step && !step.ignoreForShield) {
			finishedMissionsCount++;
		}
	});

	return finishedMissionsCount;
}

export default function Header({
	user,
	userReport,
	steps,
	setCurrentPage,
	currentPage,
}) {
	const [openSuportModal, setOpenSuportModal] = useState(false);
	function logoff() {
		localStorage.removeItem('SUT');
		window.location.href = '/';
	}

	function showToastWrapper() {
		showToast({ text: `Você não pode sair do jogo`, type: 'error' });
	}

	// const playing = currentPage === 'game' || currentPage === 'video';
	const playing = currentPage === 'game';
	if (!userReport) return;
	return (
		<div className={`${styles.header} ${isMobile && styles.mobile}`}>
			<SuportModal
				isOpen={openSuportModal}
				setIsOpen={setOpenSuportModal}
			/>

			<Logo
				onClick={() => {
					playing ? showToastWrapper() : setCurrentPage('home');
				}}
				className={`${styles.logo}`}
			/>
			<h4>CÓDIGO DE CONDUTA SICREDI</h4>
			<UserData user={user} />
			<Shield
				onClick={() =>
					playing ? showToastWrapper() : setCurrentPage('shield')
				}
				steps={steps}
				userReport={userReport}
			/>
			<div className={styles.logffWrapper}>
				<Support
					onClick={() => setOpenSuportModal(true)}
					className={styles.icons}
				/>
				<a className={styles.logoff} onClick={() => logoff()}>
					<LogOff className={styles.icons} />
				</a>
			</div>
			{adminList.includes(user.id) && (
				<ReportLogo
					onClick={() => setCurrentPage('reports')}
					className={styles.icons}
				/>
			)}
		</div>
	);
}

function Shield({ userReport, steps, onClick, className }) {
	return (
		<div onClick={onClick} className={`${styles.shield} ${className}`}>
			<ShieldLogo />
			<div className={styles.scoreWrapper}>
				{getFinishedMissions(steps, userReport.missions)}/14
			</div>
		</div>
	);
}

function UserData({ user }) {
	const avatarSelector = {
		0: avatar1,
		1: avatar2,
		2: avatar3,
		3: avatar4,
		4: avatar5,
		5: avatar6,
	};
	const truncateName = (name, maxLength = 15) => {
		if (name.length > maxLength) {
			return `${name.slice(0, maxLength)}...`;
		}
		return name;
	};

	return (
		<div className={styles.userData}>
			<img src={avatarSelector[user.avatar]} />

			<div className={styles.userName}>{truncateName(user.name)}</div>
		</div>
	);
}
