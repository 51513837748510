export default function ShieldLogo(props) {
	return (
		<svg
			{...props}
			width="60"
			height="61"
			viewBox="0 0 42 41"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M20.4023 0.793162L22.4918 2.85473L33.7221 7.89895L36.5835 7.90343L36.3419 10.867L39.3641 23.3056L41.0127 25.6585L38.4508 27.1758L30.9809 36.9409L30.3039 39.7194L27.4948 38.7652L14.9773 39.0169L12.3329 40.1644L11.3505 37.3861L3.3506 27.7863L0.85978 26.4149L2.41067 23.9726L4.93428 11.735L4.34417 8.94853L7.29563 8.5387L18.5216 2.93322L20.4023 0.793162Z"
				fill="#626262"
			/>
			<path
				d="M20.8227 4.3606L33.9603 10.6873L37.205 24.9034L28.1135 36.3038H13.5318L4.44032 24.9034L7.68504 10.6873L20.8227 4.3606Z"
				fill="#0EA268"
			/>
			<path
				d="M20.8227 7.56445L24.4358 13.493L31.3237 12.6215L28.9413 19.1428L33.9172 23.9845L27.3333 26.1879L26.6503 33.097L20.8227 29.3232L14.995 33.097L14.312 26.1879L7.72808 23.9845L12.704 19.1428L10.3216 12.6215L17.2095 13.493L20.8227 7.56445Z"
				fill="#FFCC00"
			/>
		</svg>
	);
}
