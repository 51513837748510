import styles from './escape4.module.scss';

export const escapeLive4 = {
	missionId: 10,
	bgImage: '/escape/scenario4/bg.png',
	interestingPoints: [
		{
			type: 'image',
			onClick: {
				type: 'image',
				img: '/escape/scenario4/escape1.jpg',
			},
			className: `${styles.scenario_image_1} ${styles.locks}`,
		},
		{
			lockId: 'scenario_4_lock_1',
			type: 'lock',
			onClick: {
				lockId: 'scenario_4_lock_1',
				redirectTo: null,
				type: 'lock',
				password: '915571',
				inputType: 'number',
				bodyContent: {
					img: '/escape/scenario4/lock1.jpg',
				},
			},
			className: `${styles.scenario_lock_1} ${styles.locks}`,
			onClickOpen: {
				type: 'image',
				img: '/escape/scenario4/reward_1.png',
			},
		},
		{
			lockId: 'scenario_4_lock_2',
			type: 'lock',

			onClick: {
				lockId: 'scenario_4_lock_2',
				type: 'lock',
				missionId: 10,
				redirectTo: {
					type: 'game',
					missionId: 11,
				},
				password: '182336',
				inputType: 'number',
				bodyContent: {
					img: '/escape/scenario4/lock2.png',
				},
			},
			className: `${styles.scenario_lock_3} ${styles.locks}`,
		},
	],
	redirectTo: {
		type: 'game',
		missionId: 11,
	},
};
