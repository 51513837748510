import styles from './complaints.module.scss';
import asset1 from './asset1.png';
import asset2 from './asset2.png';
import triangle from './triangle.png';
import { Email, Phone } from './icons';
import qr from './qrcode.png';
export default function Complaints({setCurrentPage}) {

	function handleOpenLink(link) {
		window.open(link, '_blank');
	}
	

	return (
		<div className={styles.complaints}>
			<div className={styles.left}>
				{/* <img src={asset1} /> */}
			</div>
			<div className={styles.body}>
				<h1>CANAL DE DENÚNCIAS</h1>
				<span>
					Se você presenciou ou passou por qualquer situação de
					descumprimento do nosso Código de Conduta ou se você tem
					conhecimento de alguma prática ilegal envolvendo o Sicredi,
					reporte ao nosso Canal de Denúncias ou entre em contato com
					a área de Compliance.
				</span>
				<h1 className={styles.green}>O QUE PODEMOS REPORTAR ?</h1>
				<span>
					No canal de denúncias, é possível denunciar desvios de
					conduta, conflitos de interesse, assédio moral, assédio
					sexual, atos de corrupção, preconceito, discriminação e
					qualquer violação do código de conduta e/ou demais indícios
					de ilicitudes.
				</span>
				<div className={styles.footer}>
					<div className={styles.infoWrapper}>
						<div className={styles.item}>
							<Phone />
							<span>0800 602 6938</span>
						</div>
						<div className={styles.item}>
							<Email />

							<span style={{cursor: 'pointer'}} onClick={()=>{handleOpenLink('https://www.contatoseguro.com.br/sicredi')}}>
								https://www.contatoseguro.com.br/sicredi
							</span>
						</div>
					</div>
					<div className={styles.qrCode}>
						<img src={qr} />
					</div>
				</div>
			</div>
			<div className={styles.rigth}>
				<img src={asset2} />
				<div className={styles.btnWrapper}>
					<div onClick={()=>{setCurrentPage('congradulations')}} className={styles.btn}>
						<img src={triangle} />
						<span>CONTINUAR</span>
					</div>
				</div>
			</div>
		</div>
	);
}
