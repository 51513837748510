export const selectOptions6 = {
	missionId: 42,
	img: '/sicred/city2.png',
	auxImg: '/quiz/quiz01_aux.png',
    corrects: 6,
	redirectTo: {
		type: 'complaints', 
		missionId: 3,
	},
	options: [
        {
            id: 'selectOption6_1',
            text: 'Ter algum controle (planilha, aplicativo) de receitas e despesas mensais.',
            score: 50
        },
        {
            id: 'selectOption6_2',
            text: 'Emitir cheques sem fundos.',
            score: 0
        },
        {
            id: 'selectOption6_3',
            text: 'Contrair despesas e prestações além do que você pode honrar.',
            score: 0
        },
        {
            id: 'selectOption6_4',
            text: 'Contrair empréstimos com agiotas. ',
            score: 0
        },
        {
            id: 'selectOption6_5',
            text: 'Manter alguma reserva financeira para algum imprevisto futuro.',
            score: 50
        },
        {
            id: 'selectOption6_6',
            text: 'Buscar informação e capacitação sobre educação financeira.',
            score: 50
        },
        {
            id: 'selectOption6_7',
            text: 'Pedir empréstimos a associados.',
            score: 0
        },
        {
            id: 'selectOption6_8',
            text: 'Acompanhar o podcast do Sicredi "Análise do Dia", no Spotify.',
            score: 50
        },
        {
            id: 'selectOption6_9',
            text: 'Estabelecer limites conscientes de compra no cartão de crédito.',
            score: 50
        },
        {
            id: 'selectOption6_10',
            text: 'Cuidar para não entrar na lista de órgãos restritivos financeiros.',
            score: 50
        },
        {
            id: 'selectOption6_11',
            text: 'Usar permanentemente o limite do cheque especial.',
            score: 0
        },
        {
            id: 'selectOption6_12',
            text: 'Parcelar frequentemente a fatura do cartão de crédito.',
            score: 0
        },
    ]
};
