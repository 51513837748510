import { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import Player from '@vimeo/player';
import { getApiClient } from 'src/services/axios';
import styles from './video.module.scss';

export default function Video({ video, setCurrentPage, setPage }) {
	const isTutorial =
		video.videoUrl ===
			'https://vimeo.com/1001187567/3f7c8472c1?share=copy' ||
		video.videoUrl === 'https://vimeo.com/1001188022/c9d913f488?share=copy';
	const [isVisibleBtn, setIsVisibleBtn] = useState(isTutorial ? true : false);
	const { playerId } = useParams();
	const vimeoContainerRef = useRef(null);
	useEffect(() => {
		const timer = setTimeout(() => {
			const player = new Player(vimeoContainerRef.current, {
				url: video.videoUrl,
				width: '100%',
				height: '100%',
				autoplay: true,
			});

			player.on('ended', () => {
				setIsVisibleBtn(true);
			});
		}, 100);

		return () => {
			clearTimeout(timer);
		};
	}, [video.videoUrl]);

	async function updateVideoMission() {
		/// tutorial
		if (isTutorial) {
			setCurrentPage('home');
			return;
		}

		const token = localStorage.getItem('SUT');
		await getApiClient(token).post(`/updateReport/${playerId}`, {
			missions: [video.missionId],
			choices: [],
			selectOptionsChoices: [],
		});
		const redirect = video.redirectTo;
		if (redirect) {
			setPage(redirect.type, redirect.missionId);
			return;
		}
		setCurrentPage('complaints');
	}

	return (
		<div className={styles.tutorial}>
			<div ref={vimeoContainerRef} className={styles.video}></div>
			{isVisibleBtn && (
				<button onClick={() => updateVideoMission()}>
					{isTutorial ? 'VOLTAR' : 'CONTINUAR'}
				</button>
			)}
		</div>
	);
}
