export const selectOptions4 = {
	missionId: 28,
	img: '/sicred/city2.png',
	auxImg: '/quiz/quiz01_aux.png',
    corrects: 4,
	redirectTo: {
		type: 'complaints', 
		missionId: 3,
	}, 
	options: [
        {
            id: 'selectOption4_1',
            text: 'Quando surgem discussões políticas durante uma interação profissional, o correto é desviar a conversa para temas mais neutros, especialmente se houver críticas a figuras públicas. Isso preserva a neutralidade da organização e evita envolver-se em controvérsias que possam prejudicar a imagem da instituição.',
            score: 50
        },
        {
            id: 'selectOption4_2',
            text: 'Ignorar sinais de que uma transação pode ter finalidades inadequadas ou ilegais, como o financiamento irregular de campanhas políticas, é uma atitude que vai contra as boas práticas da instituição e pode trazer consequências graves.',
            score: 0
        },
        {
            id: 'selectOption4_3',
            text: 'Participar ativamente de debates ou conversas sobre política, especialmente tomando partido, pode comprometer a imparcialidade da organização e criar situações desconfortáveis. Isso deve ser evitado em um contexto profissional.',
            score: 0
        },
        {
            id: 'selectOption4_4',
            text: 'Se for identificado que um associado deseja utilizar os serviços da instituição para fins inadequados ou ilícitos, como financiar atividades políticas de forma irregular, a conduta esperada é negar o pedido e agir conforme as diretrizes éticas da organização.',
            score: 50
        },
        {
            id: 'selectOption4_5',
            text: 'Ao ouvir sobre possíveis irregularidades envolvendo colegas ou a própria organização, não agir ou ignorar essas informações é uma falha. Isso pode colocar a reputação da instituição em risco.',
            score: 0
        },
        {
            id: 'selectOption4_6',
            text: 'Divulgar informações internas ou confidenciais em ambientes informais, como durante eventos sociais, é uma conduta inadequada que pode comprometer a segurança e a imagem da organização.',
            score: 0
        },
        {
            id: 'selectOption4_7',
            text: 'Quando surgem indícios de comportamento inadequado por parte de colegas ou parceiros da organização, é importante apurar discretamente os fatos e reportar a situação aos superiores. Isso ajuda a proteger a integridade e a reputação da instituição.',
            score: 50
        },
        {
            id: 'selectOption4_8',
            text: 'Em qualquer situação que envolva questões políticas, é essencial manter uma postura imparcial e profissional, evitando expressar opiniões que possam ser interpretadas como um apoio ou crítica a figuras ou partidos políticos.',
            score: 50
        },
    ]
};
