export const quiz13 = {
	missionId: 44,
	img: '/sicred/city2.png',
	auxImg: '/quiz/quiz01_aux.png',
	redirectTo: {
		type: 'game',
		missionId: 45
	},
	questionTxt:
		'Você está passeando de carro no final de semana e passa pelo lago municipal. Um grupo de apoiadores de um partido político pede para adesivar seu veículo. Você se recorda de que estaciona seu carro diariamente bem em frente à entrada principal do Sicredi. O que fazer?',
	alternatives: [
		{
			id: 1,
			text: 'A - Decidi não adesivar, para poder continuar parando o carro em frente a agência. Ou, se decidir adesivar, sabe que terá que mudar o local onde estaciona o carro, para que não vinculem a sua escolha política à marca do Sicredi.',
			next: null,
			redirectTo: {
				type: 'video',
				missionId: 5,
			},
			score: 100,
			correct: true
		},
		{
			id: 2,
			text: 'B -  Aceita, mas com um adesivo pequeno, pois você tem o direito de apoiar seu candidato politico.',
			next: null,
			redirectTo: {
				type: 'video',
				missionId: 5,
			},
			score: 0,
			correct: false

		},
		{
			id: 3,
			text: 'C - Aceita, pois o seu carro é um veiculo particular e você tem direito de apoiar  um partido político, sendo assim se eu estacionar a frente de uma agencia, nao tem ligação com o Sicredi pois a rua é pública.',
			next: null,
			redirectTo: {
				type: 'video',
				missionId: 5,
			},
			score: 0,
			correct: false

		},
		{
			id: 4,
			text: 'D -  Não aceita, pessoas colaboradores do Sicredi devem evitar qualquer partido político dentro das imediações da corporação, pois essa é a lei dentro do Sicredi e todas as pessoas colaboradoras precisam cumprir.',
			next: null,
			redirectTo: {
				type: 'game',
				missionId: 31,
			},
			score: 0,
			correct: false
		},
	],
};
