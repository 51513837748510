import styles from './escape10.module.scss';

export const escapeLive10 = {
	missionId: 31,
	bgImage: '/escape/scenario10/bg.png',
	interestingPoints: [

		{
			lockId: 'scenario_10_lock_1',
			type: 'lock',
			onClick: {
				lockId: 'scenario_10_lock_1',
				redirectTo: null,
				type: 'lock',
				password: '2513',
				inputType: 'number',
				bodyContent: {
					img: '/escape/scenario10/lock1.png',
				},
			},
			className: `${styles.scenario_lock_1} ${styles.locks}`,
			onClickOpen: {
				type: 'image',
				img: '/escape/scenario10/reward_1.png',
			},
		},
		{
			lockId: 'scenario_10_lock_2',
			type: 'lock',

			onClick: {
				lockId: 'scenario_10_lock_2',
				type: 'lock',
				missionId: 29,
				
				password: '40',
				inputType: 'number',
				bodyContent: {
					img: '/escape/scenario10/lock2.png',
				},
			},
			onClickOpen: {
				type: 'image',
				img: '/escape/scenario10/reward_2.png',
			},
			className: `${styles.scenario_lock_2} ${styles.locks}`,
		},
		{
			lockId: 'scenario_10_lock_3',
			type: 'lock',

			onClick: {
				lockId: 'scenario_10_lock_3',
				type: 'lock',
				missionId: 29,
				redirectTo: {
					type: 'game',
					missionId: 32,
				},
				password: 'ACE',
				inputType: 'text',
				bodyContent: {
					img: '/escape/scenario10/lock3.png',
				},
			},
			className: `${styles.scenario_lock_3} ${styles.locks}`,
		},
	],
	redirectTo: {
		type: 'game',
		missionId: 32,
	},
};
