/* eslint-disable jsx-a11y/alt-text */
import styles from './body.module.scss';
import { isMobile } from 'react-device-detect';
import TutorialSVG from './Tutorial'

export default function Body({
	setCurrentPage,
	preGameContent,
	setIsModalOpen,
	setVideo,
}) {
	function openTutorial() {
		setVideo({
			missionId: 99999,
			type: 'video',
			videoUrl: 'https://vimeo.com/1008931406/56cb7bbfa9?share=copy',
		});
		setCurrentPage('video');
	}
	return (
		<div className={`${styles.body} ${isMobile && styles.mobile}`}>
			<div className={styles.header}>
				<img src="/sicred/sicred_logo.png" />
				<img src="/menu/logoEtica.png" />
			</div>
			<div className={styles.menuBody}>
				{preGameContent ? (
					<div className={styles.preGaming}>
						<div className={styles.left}>
							<img src={preGameContent.preGame.image} />
							<button
								onClick={() => setIsModalOpen(true)}
								className={styles.btn}
							>
								INICIAR
							</button>
						</div>
						<div className={styles.rigth}>
							<div className={styles.textWrapper}>
								<h3>{preGameContent.preGame.title}</h3>
								<span>{preGameContent.preGame.text}</span>
							</div>
						</div>
					</div>
				) : (
					<>
						<div className={styles.left}>
							<div
								onClick={() => openTutorial()}
								className={styles.tutorialContainer}
							>
								<TutorialSVG />
							</div>
							<img
								className={styles.guardian}
								src="/menu/guardian.png"
							/>
						</div>
						<div className={styles.wrapperBody}>
							<div>
								<h2>BEM VINDO(A)!</h2>
								<span>
									Em um mundo onde as decisões podem moldar o
									futuro, as pessoas colaboradoras do Sicredi
									são chamadas para uma missão especial:
									construir o Escudo da Integridade, uma
									poderosa relíquia que simboliza a força e os
									valores da organização. Para completá-lo, é
									necessário enfrentar 14 desafios, cada um
									representando um aspecto do código de
									conduta na prática.
								</span>
								<br />
								<br />
								<span>
									A cada semana teremos um novo desafio para
									você. Você pode escolher todos os desafios
									abaixo que estão disponíveis e ir fazendo no
									seu próprio tempo, mas é muito importante
									você completar todos e garantir todas as 14
									peças do seu Escudo da Integridade, sua
									grande recompensa final.
								</span>
							</div>

							<span className={styles.italic}>
								CLIQUE EM UMA DAS MISSÕES ABAIXO PARA SEGUIR
								PARA OS DESAFIOS.
							</span>
						</div>
					</>
				)}
			</div>
		</div>
	);
}
