import styles from './modalContent.module.scss';

export default function ModalContentQuiz({ correctAlternative, quiz }) {
	return (
		<div className={styles.modalContent}>
			<span>
				{correctAlternative
					? 'Muito bem! Você esta alinhado com o conteúdo.'
					: incorrectAlternativeText(quiz.alternatives)}
			</span>
			<span>
				{quiz.redirectTo.type === 'game' &&
					' Vamos para a próxima etapa. Um novo dia começou e agora você tem mais um desafio pela frente. Atenção, você tem apenas 6min para concluir esta etapa.'}
				{quiz.redirectTo.type === 'video' &&
					'Agora que finalizamos todos os nossos desafios, vamos assistir um vídeo de conclusão.'}
			</span>
		</div>
	);
}
function incorrectAlternativeText(alternatives) {
	let correct = '';
	alternatives.forEach((alternative) => {
		if (alternative.correct) {
			correct = alternative.text;
		}
	});
	return `Uma pena, mas a resposta correta era: ${correct}`;
}
