import styles from './escape12.module.scss';

export const escapeLive12 = {
	missionId: 38,
	bgImage: '/escape/scenario12/bg.png',
	interestingPoints: [
		{
			type: 'image',
			onClick: {
				type: 'image',
				img: '/escape/scenario12/escape1.png',
			},
			className: `${styles.scenario_image_1} ${styles.locks}`,
		},

		{
			lockId: 'scenario_12_lock_1',
			type: 'lock',
			onClick: {
				lockId: 'scenario_12_lock_1',
				redirectTo: null,
				type: 'lock',
				password: 'A1A2B2B3C3',
				inputType: 'text',
				bodyContent: {
					img: '/escape/scenario12/lock1.png',
				},
			},
			className: `${styles.scenario_lock_1} ${styles.locks}`,
			onClickOpen: {
				type: 'image',
				img: '/escape/scenario12/reward_1.png',
			},
		},
		{
			lockId: 'scenario_12_lock_3',
			type: 'lock',

			onClick: {
				lockId: 'scenario_12_lock_3',
				type: 'lock',
				missionId: 38,
				redirectTo: {
					type: 'game',
					missionId: 39,
				},
				password: 'TRANSPARENCIA',
				inputType: 'text',
				bodyContent: {
					img: '/escape/scenario12/lock2.png',
				},
			},
			className: `${styles.scenario_lock_2} ${styles.locks}`,
		},
	],
	redirectTo: {
		type: 'game',
		missionId: 39,
	},
};
