
export const quiz4= {
	missionId: 11,
	img: '/sicred/city2.png',
	auxImg: '/quiz/quiz01_aux.png',
	redirectTo: {
		type: 'video',
		missionId: 12
	},
	questionTxt:
		'Um jornalista inﬂuente em sua cidade se aproxima de você, pessoa colaboradora do Sicredi, buscando informações sobre a situação financeira ou qualquer outra informação interna de um associado que esta concorrendo a um cargo politico conhecido na comunidade, alegando que isso é de interesse público. O que fazer?',
	alternatives: [
		{
			id: 1,
			text: 'A - Concordar em compartilhar as informações financeiras do associado em troca de uma cobertura positiva para o Sicredi.',
			next: null,
			redirectTo: {
				type: 'video',
				missionId: 5,
			},
			score: 0,
			correct: false
		},
		{
			id: 2,
			text: 'B -  Sugerir ao jornalista que procure a área correta do Sicredi, onde irão divulgar as informações financeiras do associado.',
			next: null,
			redirectTo: {
				type: 'video',
				missionId: 5,
			},
			score: 0,
			correct: false

		},
		{
			id: 3,
			text: 'C - Dizer ao jornalista que não pode fornecer as informações no momento, mas insinuar que poderia fazê-lo em uma data futura, após falar com seu supervisor.',
			next: null,
			redirectTo: {
				type: 'video',
				missionId: 5,
			},
			score: 0,
			correct: false

		},
		{
			id: 4,
			text: 'D - Comunico  ao  jornalista  que  não  podem  ser compartilhadas quaisquer informações, financeiras ou de cadastro, de um associado.',
			next: null,
			redirectTo: {
				type: 'video',
				missionId: 5,
			},
			score: 100,
			correct: true

		},
	],
};
