import ShieldComponent from '../ShieldComponent/ShieldComponent';
import styles from './shieldPage.module.scss';
import HomeBtn from '../HomeBtn/HomeBtn'
import Lock from '../Home/Stepper/lock'

export default function ShieldPage({ steps, missions, goToMenu }) {
   
	return (
		<div className={styles.shieldPage}>
			<div className={styles.wrapper}>
            <div className={styles.missions}>
				<MissionsAccomplished steps={steps} missions={missions} />
			</div>
			<div className={styles.shield}>
				<ShieldComponent missions={missions} />
			</div>
            <HomeBtn goToMenu={goToMenu}/>
            </div>
		</div>
	);
}

function MissionsAccomplished({ steps, missions }) {

    
	function userFinishedMission(missionId) {
		return missions.includes(missionId);
	}

	return (
        <div className={styles.accomplished}>
        {steps.map((step, i) => {
            if (step.hidden) return null;
            return (
                <div
                    className={`${styles.step} ${
                        step.unlocked ? styles.show : ''
                    }`}
                    key={`step_${i}`}
                >
                    <div className={styles.hexagon}>
                        {userFinishedMission(step.finishedMissionId) ? (
                            <img src={step.img} alt={step.label} />
                        ) : (
                            <Lock />
                        )}
                    </div>
                </div>
            );
        })}
    </div>
	);
}
