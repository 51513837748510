import styles from './escape6.module.scss';

export const escapeLive6 = {
	missionId: 17,
	bgImage: '/escape/scenario6/bg.png',
	interestingPoints: [
		{
			type: 'image',
			onClick: {
				type: 'image',
				img: '/escape/scenario6/escape1.png',
			},
			className: `${styles.scenario_image_1} ${styles.locks}`,
		},
		{
			type: 'image',
			onClick: {
				type: 'image',
				img: '/escape/scenario6/escape2.png',
			},
			className: `${styles.scenario_image_2} ${styles.locks}`,
		},
		{
			lockId: 'scenario_6_lock_1',
			type: 'lock',
			onClick: {
				lockId: 'scenario_6_lock_1',
				redirectTo: null,
				type: 'lock',
				password: 'ECCETD',
				inputType: 'text',
				bodyContent: {
					img: '/escape/scenario6/lock1.png',
				},
			},
			className: `${styles.scenario_lock_1} ${styles.locks}`,
			onClickOpen: {
				type: 'image',
				img: '/escape/scenario6/reward_1.png',
			},
		},
		{
			lockId: 'scenario_6_lock_2',
			type: 'lock',

			onClick: {
				lockId: 'scenario_6_lock_2',
				type: 'lock',
				missionId: 17,
				redirectTo: {
					type: 'game',
					missionId: 18,
				},
				password: '89,50',
				inputType: 'text',
				customInputText: 'Senha de 4 digitos (insira a virgula):',
				bodyContent: {
					img: '/escape/scenario6/lock2.png',
				},
			},
			className: `${styles.scenario_lock_2} ${styles.locks}`,
		},
	],
	redirectTo: {
		type: 'game',
		missionId: 18,
	},
};
