import showToast from '../utils/toast';

export default function validate(formData) {
	if (formData.name.length < 4) {
		showToast({
			text: 'O nome deve ter pelo menos 5 caracteres',
			type: 'error',
		});
		return false;
	}
	if(!formData.email.includes('sicredi.com.br')){
		showToast({ text: 'Você deve utilizar seu email corporativo', type: 'error' });
		return
	}
	const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
	if (!emailRegex.test(formData.email)) {
		showToast({ text: 'Insira um email válido', type: 'error' });
		return false;
	}
	if (formData.password !== formData.confirmPassword) {
		showToast({
			text: 'Senha e confirmação de senha não conferem',
			type: 'error',
		});
		return false;
	}

	if (formData.avatar !== 0 && formData.avatar > 5) {
		showToast({ text: 'Selecione um avatar válido', type: 'error' });
		return false;
	}
	if (formData.organization === '') {
		showToast({ text: 'Selecione uma cooperativa', type: 'error' });
		return false;
	}
	return true;
}
