
export const quiz2 = {
	missionId: 4,
	img: '/weeksImgs/week1/td2.png',
	auxImg: '/quiz/quiz01_aux.png',
	redirectTo: {
		type: 'video',
		missionId: 5
	},
	questionTxt:
		'Você deseja expressar seu apoio a um candidato político nas redes sociais e planeja fazer uma postagem usando sua conta pessoal. No entanto, sua biografia e postagens mencionam que você é uma pessoa colaboradora do Sicredi. Qual é a conduta adequada a seguir?',
	alternatives: [
		{
			id: 1,
			text: 'A -  Realizo a postagem, pois a minha conta pessoal é de minha responsabilidade.',
			next: null,
			redirectTo: {
				type: 'video',
				missionId: 5,
			},
			score: 0,
			correct: false
		},
		{
			id: 2,
			text: 'B -  Não realizo a postagem pois ela pode ter repercussões com pessoas que tenham opiniões políticas diferentes da minha.',
			next: null,
			redirectTo: {
				type: 'video',
				missionId: 5,
			},
			score: 0,
			correct: false

		},
		{
			id: 3,
			text: 'C - Não realizo a postagem, nem interações, pois a comunidade pode me vincular e o Sicredi em postagens de cunho político-partidário nas redes sociais.',
			next: null,
			redirectTo: {
				type: 'video',
				missionId: 5,
			},
			score: 100,
			correct: true

		},
		{
			id: 4,
			text: 'D - Antes de postar chamo a minha supervisão para conversar e saber se posso ou não fazer a postagem.',
			next: null,
			redirectTo: {
				type: 'video',
				missionId: 5,
			},
			score: 0,
			correct: false

		},
	],
};
