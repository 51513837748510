import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import styles from './home.module.scss';
import Header from 'src/components/Home/Header/Header';
import Body from 'src/components/Home/Body/Body';
import CustomStepper from 'src/components/Home/Stepper/Stepper';
import Game from 'src/components/Game/Game';
import { jwtDecode } from 'jwt-decode';
import Video from 'src/components/Video/Video';
import { useQuery } from 'src/hooks/queryClient';
import GenericModal from 'src/components/Generics/Modal/Modal';
import Complaints from 'src/components/Complaints/Complaints';
import Congradulations from 'src/components/Congradulations/Congradulations';
import ShieldPage from 'src/components/ShieldPage/ShieldPage';
import ReactLoading from 'react-loading';
import { steps } from 'src/components/Steps/steps';
import showToast from 'src/components/utils/toast';
import Reports from 'src/components/reports/ReportPlayers';

function getPlayerData() {
	const token = localStorage.getItem('SUT');
	if (!token) {
		window.location.href = '/';
	}
	return jwtDecode(token);
}

export default function Home() {
	const [user, setUser] = useState(null);
	const [currentPage, setCurrentPage] = useState('home');
	const [game, setGame] = useState(null);
	const { playerId } = useParams();
	const token = localStorage.getItem('SUT');
	const [preGameContent, setPreGameContent] = useState(null);
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [video, setVideo] = useState(null);

	useEffect(() => {
		setUser(getPlayerData());
	}, []);

	const { data: userReport, status } = useQuery({
		url: `/getReport/${playerId}`,
		key: 'playerReport',
		enabled: !!token,
		token: token,
	});
	function handleSelectGame() {
		setGame(preGameContent);
		if (preGameContent.type === 'video') {
			setVideo(preGameContent);
		}
		setCurrentPage(preGameContent.pageType);
		setPreGameContent(null);
		setIsModalOpen(false);
	}

	function setPage(pageType, gameId) {
		setCurrentPage(pageType);
		if (pageType === 'video') {
			setVideo(steps[gameId - 1]);
			return;
		}
		setGame(steps[gameId - 1]);
	}
	if (!user || !userReport) return;
	function goToMenu() {
		setCurrentPage('home');
		setGame(null);
		setPreGameContent(null);
		setVideo(null);
	}
	const playing = ['game', 'video', 'congradulations', 'shield'];

	if ('message' in userReport) {
		if (userReport.message === 'User not found') {
			localStorage.removeItem('SUT');
			showToast({
				text: 'Usuário não encontrado, você será redirecionado para o login',
				type: 'error',
			});
			setTimeout(() => {
				window.location.href = '/';
			}, 3000);
		}
		return;
	}

	return status === 'success' ? (
		<div className={styles.home}>
			<div className={styles.headerWrapper}>
				<Header
					currentPage={currentPage}
					userReport={userReport}
					steps={steps}
					setCurrentPage={setCurrentPage}
					user={user}
				/>
			</div>
			{currentPage === 'game' && (
				<Game
					userReport={userReport}
					setPage={setPage}
					setCurrentPage={setCurrentPage}
					game={game}
				/>
			)}
			{currentPage === 'video' && (
				<Video
					video={video}
					setPage={setPage}
					setCurrentPage={setCurrentPage}
				/>
			)}

			{currentPage === 'congradulations' && (
				<Congradulations
					goToMenu={goToMenu}
					missions={userReport.missions}
				/>
			)}

			{currentPage === 'shield' && (
				<ShieldPage
					goToMenu={goToMenu}
					steps={steps}
					missions={userReport.missions}
				/>
			)}
			{!playing.includes(currentPage) && (
				<div className={styles.wrapper}>
					{currentPage === 'home' && (
						<>
							<Body
								setVideo={setVideo}
								setIsModalOpen={setIsModalOpen}
								setCurrentPage={setCurrentPage}
								preGameContent={preGameContent}
							/>
							<CustomStepper
								preGameContent={preGameContent}
								setCurrentPage={setCurrentPage}
								userReport={userReport}
								setPreGameContent={setPreGameContent}
								steps={steps}
								activeStep={
									preGameContent
										? preGameContent.missionId - 1
										: null
								}
							/>
						</>
					)}

					{currentPage === 'complaints' && (
						<Complaints setCurrentPage={setCurrentPage} />
					)}

					{currentPage === 'reports' && (
						<Reports user={user} setCurrentPage={setCurrentPage} />
					)}

					<GenericModal
						onClick={() => handleSelectGame()}
						isOpen={isModalOpen}
						setIsOpen={setIsModalOpen}
						content={preGameContent}
						setCurrentPage={setCurrentPage}
						setVideo={setVideo}
						tutorial={true}
					/>
				</div>
			)}
		</div>
	) : (
		<div className={styles.loading}>
			<ReactLoading
				type={'spin'}
				color={'white'}
				height={'100%'}
				width={'100%'}
			/>
		</div>
	);
}
