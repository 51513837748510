import { useState } from 'react';
import styles from './signup.module.scss';
import { getApiClient } from '../../services/axios';
import showToast from '../utils/toast';
import validate from './validate';
import PasswordStrengthBar from 'react-password-strength-bar';
import AvatarsSelector from '../AvatarsSelector/AvatarsSelector';
import { isMobile } from 'react-device-detect';
import { Eye, EyeClosedOutline } from './icons';

export default function Signup() {
	const scoreWords = ['Fraca', 'Ok', 'Boa', 'Excelente'];
	const [avatarId, setAvatarId] = useState(0);
	const [formData, setFormData] = useState({
		email: '',
		name: '',
		password: '',
		confirmPassword: '',
		avatar: avatarId,
		organization: '',
	});
	const [visiblePassword, setVisiblePassword] = useState(false);

	const handleChange = (e) => {
		const { name, value } = e.target;
		if (name === 'email' && value.includes('@')) {
			const [localPart, domain] = value.split('@');
			if (domain !== 'sicredi.com.br') {
				setFormData((prevData) => ({
					...prevData,
					[name]: `${localPart}@sicredi.com.br`,
				}));
				return;
			}
		}

		setFormData((prevData) => ({
			...prevData,
			[name]: value,
		}));
	};

	const handleSubmit = async (e) => {
		e.preventDefault();

		const isValid = validate(formData);
		if (!isValid) return;
		let text = 'Cadastro efetuado com sucesso, verifique seu e-mail para reativar sua conta.';
		try {
			await getApiClient().post('/signup', {
				email: formData.email.toLowerCase(),
				name: formData.name,
				password: formData.password,
				confirmPassword: formData.confirmPassword,
				avatar: avatarId,
				organization: formData.organization,
			});

			showToast({ text: text, type: 'success' });
			setTimeout(() => {
				window.location.href = `/`;
			}, 2000);

			return;
		} catch (error) {
			text = 'Erro ao realizar cadastro';
			if (
				error.response.data.message ===
				'A user with this email address already exists'
			) {
				text = 'Email já cadastrado';
			}
			showToast({ text: text, type: 'error' });
		}
	};

	return (
		<div className={`${styles.container} ${isMobile && styles.mobile}`}>
			<form className={styles.form} onSubmit={handleSubmit}>
				<img className={styles.logo} src="/reinodaetica/reinodaetica.png" />
				<div className={styles.displayFlex}>
					<div>
						<label htmlFor="name" className={styles.label}>
							Primeiro nome:
						</label>
						<input
		 					required
							type="text"
							id="name"
							name="name"
							className={styles.input}
							value={formData.name}
							onChange={handleChange}
						/>
					</div>

					<div>
						<label htmlFor="email" className={styles.label}>
							E-mail corporativo (cooperativa):
						</label>
						<input
							required
							type="email"
							id="email"
							name="email"
							className={`${styles.input} ${styles.positionAbsolute}`}
							value={formData.email}
							onChange={handleChange}
						/>
					</div>
				</div>

				<div className={styles.displayFlex}>
					<div>
						<label htmlFor="password" className={styles.label}>
							Senha:
						</label>
						<div className={styles.passwordWrapper}>
							<input
								required
								type={visiblePassword ? 'text' : 'password'}
								id="password"
								name="password"
								className={styles.input}
								value={formData.password}
								onChange={handleChange}
							/>
							{visiblePassword ? (
								<EyeClosedOutline
									onClick={() => setVisiblePassword(false)}
									className={styles.icon}
								/>
							) : (
								<Eye
									onClick={() => setVisiblePassword(true)}
									className={styles.icon}
								/>
							)}
						</div>
					</div>
					<div>
						<label
							htmlFor="confirmPassword"
							className={styles.label}
						>
							Confirmação de Senha:
						</label>
						<input
							required
							type={visiblePassword ? 'text' : 'password'}
							id="confirmPassword"
							name="confirmPassword"
							className={styles.input}
							value={formData.confirmPassword}
							onChange={handleChange}
						/>
					</div>
				</div>
				<PasswordStrengthBar
					className={styles.passwordBar}
					shortScoreWord={'Muito fraca'}
					scoreWords={scoreWords}
					password={formData.password}
				/>

				<label className={styles.label}>Cooperativa: </label>
				<select
					className={styles.select}
					name="organization"
					onChange={handleChange}
				>
					<option value="">Selecione uma cooperativa</option>
					<option value="central">Brasil Central</option>
					<option value="pantanal"> Pantanal MS</option>
					<option value="centro_sul"> Centro-Sul MS/BA</option>
					<option value="uniao_ms_to"> União MS/TO</option>
					<option value="campo_grande">Campo Grande MS</option>
					<option value="celeiro">Celeiro Centro Oeste</option>
					<option value="cerrado"> Cerrado GO</option>
					<option value="planalto"> Planalto Central</option>
				</select>

				<label className={styles.label}>Avatar:</label>
				<AvatarsSelector
					avatarId={avatarId}
					setAvatarId={setAvatarId}
				/>

				<button type="submit" className={styles.button}>
					Cadastrar
				</button>
			</form>
		</div>
	);
}
