export const selectOptions2 = {
	missionId: 14,
	img: '/sicred/city2.png',
	auxImg: '/quiz/quiz01_aux.png',
    corrects: 6,
	redirectTo: {
		type: 'complaints', 
		missionId: 3,
	},
	options: [
        {
            id: 'selectOption2_1',
            text: 'Aceitar o recebimento de informações confidenciais de concorrentes;',
            score: 0
        },
        {
            id: 'selectOption2_2',
            text: 'Tirar foto nas dependências do Sicredi, e não observar se informações estratégicas, ou confidencias, apareceram na fotografia;',
            score: 0
        },
        {
            id: 'selectOption2_3',
            text: 'Avaliar a necessidade do envio das informações e transmitir somente o essencial, e coletar a ciência/autorização da área proprietária da informação quando necessário;',
            score: 50
        },
        {
            id: 'selectOption2_4',
            text: 'Proteger as informações adequadamente contra acessos não autorizados, e divulgar as informações financeiras somente para as pessoas autorizadas a recebê-las;',
            score: 50
        },
        {
            id: 'selectOption2_5',
            text: 'Estabelecer acordos de confidencialidade e não divulgação de informações antes de transmiti-las a terceiros; ',
            score: 50
        },
        {
            id: 'selectOption2_6',
            text: 'Utilizar de acesso a sistemas e relatórios para consultas indevidas, como consultar salários de colegas;',
            score: 0
        },
        {
            id: 'selectOption2_7',
            text: 'Evitar falar de transações comerciais e de associados em lugares públicos; ',
            score: 50
        },
        {
            id: 'selectOption2_8',
            text: 'Usar canais corporativos para a transmissão das informações; ',
            score: 50
        },
        {
            id: 'selectOption2_9',
            text: 'Compartilhar credenciais de acesso aos sistemas;',
            score: 0
        },
        {
            id: 'selectOption2_10',
            text: 'Manter o sigilo dos dados e das movimentações financeiras dos associados;',
            score: 50
        },
        {
            id: 'selectOption2_11',
            text: 'Utilizar ou divulgar dados confidenciais do Sicredi, como de pessoas colaboradoras ou de seus associados para finalidades indevidas;',
            score: 0
        },
        {
            id: 'selectOption2_12',
            text: 'Comentar com o público externo qualquer informação relativa a associados e/ou demais pessoas colaboradoras;',
            score: 0
        },
    ]
};
