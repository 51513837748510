import Escape from "../GameTypes/Escape/Escape"
import Quiz from "../GameTypes/Quiz/Quiz"
import SelectOptions from "../GameTypes/SelectOptions/SelectOptions"

export default function Game({ setCurrentPage, game, setPage, userReport}) {
	if(game.type === 'escape'){
        return <Escape setPage={setPage} userReport={userReport} setCurrentPage={setCurrentPage} missionId={game.missionId}/>
    }

	if(game.type === 'quiz'){
        return <Quiz userReport={userReport} setPage={setPage} setCurrentPage={setCurrentPage} missionId={game.missionId}/>
    }

	if(game.type === 'selectOptions'){
        return <SelectOptions userReport={userReport} setPage={setPage} setCurrentPage={setCurrentPage} missionId={game.missionId}/>
    }
}
