
export const quiz11 = {
	missionId: 37,
	img: '/sicred/city2.png',
	auxImg: '/quiz/quiz01_aux.png',
	redirectTo: {
		type: 'game',
		missionId: 38
	},
	questionTxt:
		' Você é uma pessoa colaboradora do Sicredi, tem um bom relacionamento com a comunidade e seus amigos frequentemente o(a) encorajam a concorrer a um cargo político local. Diante dessas sugestões, você pondera sobre a possibilidade de se candidatar no próximo pleito eleitoral. Levando em conta sua posição no Sicredi, qual deve ser sua conduta em relação a essa candidatura?',
	alternatives: [
		{
			id: 1,
			text: 'A - Enquanto integrar o time do Sicredi, não posso me candidatar. Mas se um dia decidir fazê-lo, comunicarei o meu superior com antecedência, e sei que terei que me afastar da minha função.',
			next: null,
			redirectTo: {
				type: 'video',
				missionId: 5,
			},
			score: 100,
			correct: true
		},
		{
			id: 2,
			text: 'B - Prossigo com a candidatura sem comunicar oficialmente a organização, mantendo-a em segredo. Pois posso usar minha posição no Sicredi para angariar votos.',
			next: null,
			redirectTo: {
				type: 'video',
				missionId: 5,
			},
			score: 0,
			correct: false

		},
		{
			id: 3,
			text: 'C - Não me candidato, mas converso com colegas sempre que encontre nas imediações do Sicredi, sobre essa possível candidatura futuramente, após meu desligamento.',
			next: null,
			redirectTo: {
				type: 'video',
				missionId: 5,
			},
			score: 0,
			correct: false

		},
		{
			id: 4,
			text: 'D -  Não vou me candidatar no momento, pois é Sicredi proíbe que uma pessoa colaboradora se candidate a uma cargo político.',
			next: null,
			redirectTo: {
				type: 'game',
				missionId: 31,
			},
			score: 0,
			correct: false
		},
	],
};
