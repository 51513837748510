/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState } from 'react';
import ShieldComponent from '../ShieldComponent/ShieldComponent';
import sicredLogo from './sicred.png';
import styles from './congradulations.module.scss';
import Confetti from 'react-confetti';
import { useWindowSize } from 'react-use';
import HomeBtn from '../HomeBtn/HomeBtn';
import { queryClient } from 'src/services/queryClient';

export default function Congradulations({ missions, goToMenu }) {
	const [showConfetti, setShowConfetti] = useState(false);
	const { width, height } = useWindowSize();

	const handleCelebrate = () => {
		setShowConfetti(true);
		setTimeout(() => {
			setShowConfetti(false);
		}, 10000);
	};
	useEffect(() => {
		handleCelebrate();
		setTimeout(() => {
			queryClient.invalidateQueries({ queryKey: ['playerReport'] });
		}, 1000);
	}, []);

	return (
		<>
			<div className={styles.congradulations}>
				<h1>PARABÉNS</h1>
				<h4>Você completou esta etapa e ganhou uma recompensa</h4>
				<div className={styles.shield}>
					<ShieldComponent missions={missions} />
				</div>
				<div className={styles.confettiWrapper}>
					{showConfetti && <Confetti width={width} height={height} />}
				</div>
				{/* <div className={styles.logo}>
					<img src={sicredLogo} alt="Sicred Logo" />
				</div> */}
				<HomeBtn goToMenu={goToMenu} />
			</div>
		</>
	);
}
