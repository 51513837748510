/* eslint-disable jsx-a11y/alt-text */
import styles from './suportModal.module.scss';
import { CloseCircleSolid } from '../Login/CloseModal';
import { Email, Phone } from '@mui/icons-material';
export default function SuportModal({
	isOpen,
	setIsOpen,
	content,
	onClick,
	closeBtnDisabled,
	className,
}) {

	function handleBackdropClick(e) {
		if (e.target === e.currentTarget) {
			setIsOpen(false);
		}
	}
	

	if (!isOpen) return;

	return (
		<>
			<div onClick={handleBackdropClick} className={styles.backdrop}>
				<div className={`${styles.modal} ${className}`}>
					{!closeBtnDisabled && (
						<CloseCircleSolid
							onClick={() => setIsOpen(false)}
							className={styles.closeModal}
						/>
					)}

					<div className={styles.body}>
                        <h1>Suporte Chave-Mestra</h1>
						<div className={styles.wrapper}>
							<Email />
							kanan@chavemestra.net
						</div>
						<div className={styles.wrapper}>
							<Phone />
							(51) 98110-1419
						</div>
					</div>
					
				</div>
			</div>
		</>
	);
}
