export default function Lock(props) {
	return (
		<svg
			{...props}
			width="40"
			height="50"
			viewBox="0 0 40 50"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<rect
				x="7.61214"
				y="1.6667"
				width="25.732"
				height="27.3301"
				rx="6.87523"
				stroke="#494949"
				strokeWidth="2.32046"
			/>
			<rect
				x="0.666773"
				y="14.0119"
				width="38.1965"
				height="34.7038"
				rx="3.4807"
				fill="#FFC107"
				stroke="#494949"
				strokeWidth="0.773488"
			/>
			<circle
				cx="19.7653"
				cy="26.963"
				r="4.01403"
				fill="#494949"
				stroke="#494949"
				strokeWidth="0.773488"
			/>
			<path
				d="M18.7314 27.9008C19.162 27.0572 20.3675 27.0572 20.7981 27.9008L25.6852 37.4741C26.0793 38.2461 25.5186 39.1619 24.6519 39.1619H14.8776C14.0109 39.1619 13.4502 38.2461 13.8443 37.4741L18.7314 27.9008Z"
				fill="#494949"
				stroke="#494949"
				strokeWidth="0.773488"
			/>
		</svg>
	);
}
