export default function HomeSVG(props) {
	return (
		<svg
			{...props}
			width="100"
			height="100"
			viewBox="0 0 329 333"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<ellipse
				cx="164.755"
				cy="166.734"
				rx="164.245"
				ry="166.223"
				fill="#00A753"
			/>
			<mask
				id="mask0_1264_29"
				// style="mask-type:alpha"
				maskUnits="userSpaceOnUse"
				x="0"
				y="0"
				width="329"
				height="333"
			>
				<ellipse
					cx="164.755"
					cy="166.734"
					rx="164.245"
					ry="166.223"
					fill="#00A753"
				/>
			</mask>
			<g mask="url(#mask0_1264_29)">
				<path
					d="M219.968 356.927L51.6354 271.883L54.2093 261.951L82.9689 258.883L91.4584 185.782L48.6216 135.173L91.4584 81.7312L233.505 74.6055L365.862 169.415L324.998 292.499L219.968 356.927Z"
					fill="#007F40"
				/>
			</g>
			<path
				d="M67.3354 127.551V259.077H252.54V111.995L161.609 39.1611L67.3354 108.459V127.551Z"
				fill="white"
			/>
			<path
				d="M161.609 39.1611V117.16L161.611 259.077H252.539V111.995L161.609 39.1611Z"
				fill="#FFF5CB"
			/>
			<path
				d="M48.6167 135.925L31.2329 112.242L158.936 16.833V54.0491L48.6167 135.925Z"
				fill="#FFC473"
			/>
			<path
				d="M269.256 135.925L286.64 112.242L158.937 16.833V54.0491L269.256 135.925Z"
				fill="#FFAA20"
			/>
			<rect
				x="51.8755"
				y="250.271"
				width="106.652"
				height="22.2013"
				fill="#9EE98F"
			/>
			<rect
				x="158.527"
				y="250.271"
				width="106.652"
				height="22.2013"
				fill="#2CD729"
			/>
			<path
				d="M248.551 173.169V187.088H229.565V213.203H250.815L249.509 226.989H219.201V137.245H249.422V151.297H229.565V173.169H248.551Z"
				fill="#007F40"
			/>
			<path
				d="M198.871 226.989V193.584C198.871 178.339 199.046 160.841 199.22 150.634H198.958C196.955 167.734 193.646 197.958 189.814 226.989H180.321C177.447 199.947 174.05 167.734 172.134 150.369H171.785C172.134 160.841 172.395 179.665 172.395 195.175V226.989H162.815V137.245H178.579C181.627 162.034 184.327 187.088 185.546 200.212H185.633C186.853 188.016 190.51 160.974 193.559 137.245H208.974V226.989H198.871Z"
				fill="#007F40"
			/>
			<path
				d="M135.407 135.919C148.297 135.919 154.394 150.368 154.394 181.52C154.394 214.263 148.558 228.58 134.972 228.58C122.256 228.58 116.073 214.528 116.073 181.255C116.073 148.91 123.301 135.919 135.407 135.919ZM135.233 149.44C128.875 149.44 126.872 161.106 126.872 181.122C126.872 202.465 129.05 215.058 135.233 215.058C141.765 215.058 143.594 203.393 143.594 181.122C143.594 160.31 141.591 149.44 135.233 149.44Z"
				fill="#007F40"
			/>
			<path
				d="M72.4023 226.989V137.245H82.9406V172.772H97.1367V137.245H107.762V226.989H97.1367V186.691H82.9406V226.989H72.4023Z"
				fill="#007F40"
			/>
		</svg>
	);
}
