import styles from './modalContent.module.scss'

export default function ModalContentEscapeLive({unlockByTime}){
    return(
        <div className={styles.modalContent}>
            <span>
            {unlockByTime ? 'Uma pena, seu tempo acabou e você não concluiu a atividade. Mas não se preocupe, ainda tem mais desafios para você pontuar.' : 'Excelente, você conseguiu completar este desafio à tempo! '}       
            </span>
            <span>
                Agora vamos seguir com um dilema e você deve escolher uma resposta para ele.
            </span>
        </div>
    )
}