import styles from './escape7.module.scss';

export const escapeLive7 = {
	missionId: 22,
	bgImage: '/escape/scenario7/bg.png',
	interestingPoints: [
		{
			lockId: 'scenario_7_lock_1',
			type: 'lock',
			onClick: {
				lockId: 'scenario_7_lock_1',
				redirectTo: null,
				type: 'switch',
				switchs: [
					{header: <div className={`${styles.circle} ${styles.red}`}></div>, on: false},
					{header: <div className={`${styles.circle} ${styles.orange}`}></div>, on: true},
					{header: <div className={`${styles.circle} ${styles.green}`}></div>, on: false},
					{header: <div className={`${styles.circle} ${styles.blue}`}></div>, on: true}
				],
				inputType: 'text',
				bodyContent: {
					img: '/escape/scenario7/lock1.png',
				},
			},
			className: `${styles.scenario_lock_1} ${styles.locks}`,
			onClickOpen: {
				type: 'image',
				img: '/escape/scenario7/reward_1.png',
			},
		},
		{
			lockId: 'scenario_7_lock_2',
			type: 'lock',
			onClick: {
				lockId: 'scenario_7_lock_2',
				redirectTo: null,
				type: 'lock',
				password: 'A3B1C2',
				inputType: 'text',
				bodyContent: {
					img: '/escape/scenario7/lock2.png',
				},
			},
			className: `${styles.scenario_lock_2} ${styles.locks}`,
			onClickOpen: {
				type: 'image',
				img: '/escape/scenario7/reward_2.png',
			},
		},
		{
			lockId: 'scenario_7_lock_3',
			type: 'lock',

			onClick: {
				lockId: 'scenario_7_lock_3',
				type: 'lock',
				missionId: 22,
				redirectTo: {
					type: 'game',
					missionId: 23,
				},
				password: '2165',
				inputType: 'number',
				bodyContent: {
					img: '/escape/scenario7/lock3.png',
				},
			},
			className: `${styles.scenario_lock_3} ${styles.locks}`,
		},
	],
	redirectTo: {
		type: 'game',
		missionId: 23,
	},
};
