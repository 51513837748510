import { useQuery as _useQuery } from "@tanstack/react-query";
import axios from "axios";
import { debounce } from "lodash";

export function useQuery({ url, key, enabled, token }) {
    const indexApi = axios.create({
        baseURL: process.env.REACT_APP_API_URL
    });
    indexApi.defaults.headers['Authorization'] = `Bearer ${token}`;

    // Create a debounced function that returns a promise
    const debouncedFetch = debounce(async () => {
        const res = await indexApi.get(url);
        return res.data;
    }, 300);

    // Wrap the debounced function in a promise
    const queryFn = () => new Promise((resolve, reject) => {
        debouncedFetch().then(resolve).catch(reject);
    });

    const query = _useQuery({
        queryKey: [key, url],
        queryFn: queryFn,
        enabled: enabled,
    }); 
    return query;
}
