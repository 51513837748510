export const quiz1 = {
	missionId: 2,
	img: '/weeksImgs/week1/td1.png',
	auxImg: '/quiz/quiz01_aux.png',
	redirectTo: {
		type: 'game',
		missionId: 3,
	},
	questionTxt:
		'Após o expediente de trabalho, você está a caminho de casa quando encontra um amigo que te convida para participar de um comício político que está acontecendo em seu bairro. Nesse dia, você está vestindo calça jeans e uma camisa com o logotipo do Sicredi. Qual seria a sua atitude?',
	alternatives: [
		{
			id: 1,
			text: 'A - Me junto ao meu amigo, afinal gosto de sua companhia e também tenho a mesma opinião que ele.',
			next: null,
			redirectTo: {
				type: 'game',
				missionId: 3,
			},
			score: 0,
			correct: false
		},
		{
			id: 2,
			text: 'B - Agradece o convite, mas recusa ir à manifestação político-partidária porque está uniformizado e com vestimenta e/ou acessórios que fazem referência ao Sicredi.',
			next: null,
			redirectTo: {
				type: 'game',
				missionId: 3,
			},
			score: 100,
			correct: true

		},
		{
			id: 3,
			text: 'C - Agradeço o convite, mas me recuso a ir pois não tenho a mesma opinião política que o meu amigo e portanto não faria sentido me junto à esta manifestação.',
			next: null,
			redirectTo: {
				type: 'game',
				missionId: 3,
			},
			score: 0,
			correct: false

		},
		{
			id: 4,
			text: 'D - Me recuso a ir pois o Sicredi não me permite de nenhuma forma, que eu expresse minhas opiniões políticas pois isto será vinculado à empresa que trabalho.',
			next: null,
			redirectTo: {
				type: 'game',
				missionId: 3,
			},
			score: 0,
			correct: false

		},
	],
};
