/* eslint-disable jsx-a11y/alt-text */
import styles from './modal.module.scss';
import { CloseCircleSolid } from './CloseModal';
import ReCAPTCHA from 'react-google-recaptcha';
import { useState } from 'react';
import { getApiClient } from 'src/services/axios';
import showToast from '../utils/toast';
import { GoogleReCaptcha, GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

export default function ForgotPassModal({ isOpen, setIsOpen }) {
	const [finishedCaptcha, setFinishedCaptcha] = useState(false);
	const [email, setEmail] = useState();

	const handleSubmit = async () => {
		try {
			if (!finishedCaptcha) {
				showToast({
					text: 'Finalize o reCAPTCHA',
					type: 'error',
				});
				return;
			}
			const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
			if (!emailRegex.test(email)) {
				showToast({
					text: 'Insira um email válido',
					type: 'error',
				});
				return;
			}
			await getApiClient().post('/request-reset-password', {
				email: email,
			});
			showToast({
				text: 'Se seu email estiver cadastrado você receberá um email com instruções para recuperar sua senha',
				type: 'success',
			});
			setTimeout(() => {
				setIsOpen(false);
			}, 3000);
		} catch (error) {
			
			showToast({
				text: 'Se seu email estiver cadastrado você receberá um email com instruções para recuperar sua senha',
				type: 'success',
			});
			setTimeout(() => {
				setIsOpen(false);
			}, 3000);
			
		}
	};
	if (!isOpen) return;
	return (
		<>
			<div className={styles.backdrop}>
				<div className={`${styles.modal}`}>
					<CloseCircleSolid
						onClick={() => setIsOpen(false)}
						className={styles.closeModal}
					/>

					<div className={styles.body}>
						<h2>Recuperação de Senha</h2>
						<form className={styles.form} onSubmit={handleSubmit}>
							<label htmlFor="email" className={styles.label}>
								E-mail corporativo (Cooperativa):
							</label>
							<input
								type="email"
								id="email"
								name="email"
								className={styles.input}
								value={email}
								onChange={(e) => {
									setEmail(e.target.value);
								}}
							/>
						</form>
					<span className={styles.red}>Verifique a caixa de spam</span>
					</div>
					<GoogleReCaptchaProvider
						reCaptchaKey={process.env.REACT_APP_RECAPTCHA_KEY}
					>
						<GoogleReCaptcha
							className="google-recaptcha-custom-class"
							onVerify={() => setFinishedCaptcha(true)}

						/>
					</GoogleReCaptchaProvider>
					<button
						onClick={() => handleSubmit()}
						className={styles.btn}
					>
						ENVIAR
					</button>
				</div>
			</div>
		</>
	);
}
