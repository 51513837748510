import React from 'react';

export const ClosedLock = (props) => (
	<svg
		{...props}
		id="Layer_1"
		data-name="Layer 1"
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 158.32 233.13"
	>
		<defs>
			<style>
				{`
          .cls-1 {
            fill: #13a36a;
          }

          .cls-1, .cls-2, .cls-3, .cls-4, .cls-5, .cls-6, .cls-7 {
            strokeWidth: 0px;
          }

          .cls-2 {
            fill: #004b32;
          }

          .cls-3 {
            fill: #00281a;
          }

          .cls-4 {
            fill: #6c8587;
          }

          .cls-5 {
            fill: #bbcdc6;
          }

          .cls-6 {
            fill: #fff;
          }

          .cls-7 {
            fill: #00804b;
          }

          .cls-8 {
            opacity: .56;
          }
        `}
			</style>
		</defs>
		<path
			className="cls-5"
			d="M80.77,194.58h-3.22c-29.28,0-53.1-23.82-53.1-53.1V53.1C24.45,23.82,48.27,0,77.55,0h3.22c29.28,0,53.1,23.82,53.1,53.1v88.39c0,29.28-23.82,53.1-53.1,53.1ZM77.55,16.79c-20.02,0-36.31,16.29-36.31,36.31v88.39c0,20.02,16.29,36.31,36.31,36.31h3.22c20.02,0,36.31-16.29,36.31-36.31V53.1c0-20.02-16.29-36.31-36.31-36.31h-3.22Z"
		/>
		<path
			className="cls-4"
			d="M80.51,11.21h-2.7c-24.57,0-44.55,19.99-44.55,44.55v74.17c0,13.58,6.12,25.76,15.73,33.94-4.85-6.17-7.75-13.94-7.75-22.38V53.1c0-20.02,16.29-36.31,36.31-36.31h3.22c20.02,0,36.31,16.29,36.31,36.31v88.39c0,8.44-2.9,16.21-7.75,22.38,9.61-8.18,15.73-20.35,15.73-33.93V55.76c0-24.57-19.99-44.55-44.55-44.55Z"
		/>
		<rect
			className="cls-7"
			y="87.66"
			width="158.32"
			height="145.47"
			rx="19.42"
			ry="19.42"
		/>
		<path
			className="cls-2"
			d="M158.32,148.21c-10.55,30.75-36.46,73.1-99.84,84.92h80.42c10.72,0,19.42-8.69,19.42-19.42v-65.5Z"
		/>
		<path
			className="cls-1"
			d="M19.42,87.66c-5.32,0-10.14,2.15-13.65,5.61,1.6,11.27,3.56,23.9,5.96,38.07,14.69,86.68,61.55-8.15,77.38-43.68H19.42Z"
		/>
		<path
			className="cls-2"
			d="M104.49,143.73c0-14.52-12.22-26.19-26.94-25.28-12.8.79-23.14,11.35-23.69,24.17-.35,8.24,3.24,15.65,9.04,20.51l-9.11,34.96c-.57,2.17,1.07,4.29,3.31,4.29h44.13c2.24,0,3.88-2.12,3.31-4.29l-9.11-34.96c5.54-4.65,9.06-11.61,9.06-19.41Z"
		/>
		<path
			className="cls-3"
			d="M91.62,165.29c4.21-4.61,6.89-11.52,6.89-19.26,0-14.41-9.3-21.53-20.49-20.63-9.74.79-17.6,6.81-18.02,19.53-.27,8.17,2.46,15.53,6.87,20.35l-6.93,34.68c-.17.85-.07,1.69.21,2.4h38.18c.28-.71.38-1.55.21-2.4l-6.93-34.68Z"
		/>
		<g className="cls-8">
			<rect
				className="cls-6"
				x=".38"
				y="137.32"
				width="24.87"
				height="2"
			/>
			<rect
				className="cls-6"
				x=".38"
				y="152.86"
				width="24.87"
				height="2"
			/>
			<rect
				className="cls-6"
				x=".38"
				y="168.41"
				width="24.87"
				height="2"
			/>
		</g>
		<path
			className="cls-6"
			d="M30.69,42.08c-.22,0-.45-.04-.67-.12-1.04-.37-1.58-1.52-1.21-2.56C39.44,9.65,65.63,3.98,66.74,3.75c1.08-.22,2.14.48,2.36,1.56.22,1.08-.48,2.14-1.56,2.36-.31.06-25.13,5.55-34.96,33.08-.29.82-1.06,1.33-1.88,1.33Z"
		/>
	</svg>
);

export const OpenedLock = (props) => (
	<svg
		{...props}
		id="Layer_1"
		data-name="Layer 1"
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 225.28 256.73"
	>
		<defs>
			<style>
				{`
          .cls-1 {
            fill: #13a36a;
          }
          .cls-1, .cls-2, .cls-3, .cls-4, .cls-5, .cls-6, .cls-7 {
            strokeWidth: 0px;
          }
          .cls-2 {
            fill: #004b32;
          }
          .cls-3 {
            fill: #00281a;
          }
          .cls-4 {
            fill: #6c8587;
          }
          .cls-5 {
            fill: #bbcdc6;
          }
          .cls-6 {
            fill: #fff;
          }
          .cls-7 {
            fill: #00804b;
          }
          .cls-8 {
            opacity: .56;
          }
        `}
			</style>
		</defs>
		<path
			className="cls-5"
			d="M56.32,0h-3.22C23.77,0,0,23.77,0,53.1v31.58c0,4.64,3.76,8.39,8.39,8.39h0c4.64,0,8.39-3.76,8.39-8.39v-31.58c0-20.05,16.26-36.31,36.31-36.31h3.22c20.06,0,36.31,16.26,36.31,36.31v88.39c0,13.86-7.81,25.93-19.25,32.04v18.23c20.93-7.12,36.04-26.96,36.04-50.27V53.1C109.41,23.77,85.64,0,56.32,0Z"
		/>
		<path
			className="cls-4"
			d="M57.83,13.04h-2.91c-26.53,0-48.03,23.77-48.03,53.1v26.8c.49.09,1,.14,1.51.14,4.64,0,8.39-3.76,8.39-8.39v-31.58c0-20.05,16.26-36.31,36.31-36.31h3.22c20.06,0,36.31,16.26,36.31,36.31v88.39c0,4.1-.7,8.03-1.95,11.71v1.33c0,13.81-7.01,25.83-17.3,31.97v5.26c14.51-4.94,26.22-15.99,32.04-30.08.29-2.34-6.68-7.57-6.68-9.99V63.3c0-29.32-14.38-50.26-40.91-50.26Z"
		/>
		<g>
			<rect
				className="cls-7"
				x="66.96"
				y="111.26"
				width="158.32"
				height="145.47"
				rx="19.42"
				ry="19.42"
			/>
			<path
				className="cls-2"
				d="M225.28,171.81c-10.55,30.75-36.46,73.1-99.84,84.92h80.42c10.72,0,19.42-8.69,19.42-19.42v-65.5Z"
			/>
			<path
				className="cls-1"
				d="M86.38,111.26c-5.32,0-10.14,2.15-13.65,5.61,1.6,11.27,3.56,23.9,5.96,38.07,14.69,86.68,61.55-8.15,77.38-43.68h-69.7Z"
			/>
			<path
				className="cls-2"
				d="M171.45,167.34c0-14.52-12.22-26.19-26.94-25.28-12.8.79-23.14,11.35-23.69,24.17-.35,8.24,3.24,15.65,9.04,20.51l-9.11,34.96c-.57,2.17,1.07,4.29,3.31,4.29h44.13c2.24,0,3.88-2.12,3.31-4.29l-9.11-34.96c5.54-4.65,9.06-11.61,9.06-19.41Z"
			/>
			<path
				className="cls-3"
				d="M158.59,188.9c4.21-4.61,6.89-11.52,6.89-19.26,0-14.41-9.3-21.53-20.49-20.63-9.74.79-17.6,6.81-18.02,19.53-.27,8.17,2.46,15.53,6.87,20.35l-6.93,34.68c-.17.85-.07,1.69.21,2.4h38.18c.28-.71.38-1.55.21-2.4l-6.93-34.68Z"
			/>
			<g className="cls-8">
				<rect
					className="cls-6"
					x="67.34"
					y="160.93"
					width="24.87"
					height="2"
				/>
				<rect
					className="cls-6"
					x="67.34"
					y="176.47"
					width="24.87"
					height="2"
				/>
				<rect
					className="cls-6"
					x="67.34"
					y="192.01"
					width="24.87"
					height="2"
				/>
			</g>
		</g>
		<path
			className="cls-6"
			d="M15.88,28.39c-.37,0-.74-.1-1.08-.32-.93-.6-1.2-1.83-.6-2.76C32.35-3.01,70.46,6.02,70.84,6.11c1.07.27,1.73,1.35,1.46,2.42-.26,1.07-1.35,1.73-2.42,1.46-.36-.09-35.72-8.42-52.32,17.47-.38.6-1.03.92-1.69.92Z"
		/>
	</svg>
);
