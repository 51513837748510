export const quiz12 = {
	missionId: 39,
	img: '/sicred/city2.png',
	auxImg: '/quiz/quiz01_aux.png',
	redirectTo: {
		type: 'video',
		missionId: 40
	},
	questionTxt:
		'Você, pessoa colaboradora do Sicredi, tem um amigo que é candidato político e precisa de um local para realizar alguns encontros. Esse amigo pede para usar o espaço da cooperativa. O que você deve fazer?',
	alternatives: [
		{
			id: 1,
			text: 'A - Permitir que seu amigo utilize o espaço do Sicredi para reuniões, mas tentar ocultar o fato de que são encontros políticos, alegando que são eventos sociais ou de outra natureza.',
			next: null,
			redirectTo: {
				type: 'video',
				missionId: 5,
			},
			score: 0,
			correct: false
		},
		{
			id: 2,
			text: 'B - Converse com seu amigo e informe que não será possível a utilização da estrutura do Sicredi. Você não deve viabilizar a utilização dos espaços físicos e recursos da Cooperativa de forma a promover uma candidatura política.',
			next: null,
			redirectTo: {
				type: 'video',
				missionId: 5,
			},
			score: 100,
			correct: true

		},
		{
			id: 3,
			text: 'C - Fingir não saber que os encontros são de natureza política e permitir que seu amigo utilize o espaço do Sicredi, alegando ignorância sobre a finalidade dos eventos.',
			next: null,
			redirectTo: {
				type: 'video',
				missionId: 5,
			},
			score: 0,
			correct: false

		},
		{
			id: 4,
			text: 'D -  Reservar o espaço do Sicredi em seu próprio nome ou em nome de outra pessoa sem relação com a campanha, mas ceder o local para que seu amigo realize os encontros.',
			next: null,
			redirectTo: {
				type: 'game',
				missionId: 31,
			},
			score: 0,
			correct: false
		},
	],
};
