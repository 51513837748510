import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import styles from './styles/styles.module.scss';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { QueryClientProvider } from '@tanstack/react-query';
import { queryClient } from '../src/services/queryClient';

import Index from './Pages/index';
import Home from './Pages/home/home';
import RecoveryPassword from './Pages/recovery-password/recovery-password';
import ActiveAccount from './Pages/active-account/active-account'

function App() {
	return (
		
			<QueryClientProvider client={queryClient}>
				<Router>
					<div className={styles.app}>
						<Routes>
							<Route path="/" element={<Index />} />
							<Route path="/:playerId" element={<Home />} />
							<Route
								path="/recovery-password/:token"
								element={<RecoveryPassword />}
							/>
							<Route
								path="/active-account/"
								element={<ActiveAccount />}
							/>

						</Routes>
						<ToastContainer />
					</div>
				</Router>
			</QueryClientProvider>
		
	);
}

export default App;
