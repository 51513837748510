import Avatar from 'react-avatar';
import avatar1 from '../../images/avatars/avatar_01.jpg';
import avatar2 from '../../images/avatars/avatar_02.jpg';
import avatar3 from '../../images/avatars/avatar_03.jpg';
import avatar4 from '../../images/avatars/avatar_04.jpg';
import avatar5 from '../../images/avatars/avatar_05.jpg';
import avatar6 from '../../images/avatars/avatar_06.jpg';
import styles from './avatarsSelector.module.scss';

export default function AvatarsSelector({ setAvatarId, avatarId }) {
	const avatarList = [avatar1, avatar2, avatar3, avatar4, avatar5, avatar6];

	const handleAvatarClick = (index) => {
		setAvatarId(index);
		// setAvatar(avatarList[index]);
	};

	return (
		<div className={styles.avatarsWrapper}>
			{avatarList.map((avatar, i) => (
				<div
					key={`avatar_${i}`}
					className={`${styles.avatar} ${
						avatarId === i ? styles.selected : ''
					}`}
					onClick={() => handleAvatarClick(i)}
				>
					<Avatar src={avatar} round="50%" />
				</div>
			))}
		</div>
	);
}
