
import styles from './escape1.module.scss';


export const escapeLive1 = {
    missionId: 1,
    bgImage: '/escape/scenario1/bg.png',
    interestingPoints: [
        {
            lockId: 'scenario_1_lock_1',
            type: 'lock',
            onClick: {
                lockId: 'scenario_1_lock_1',
                redirectTo: null,
                type: 'lock',
                password: '529',
                inputType: 'number',
                bodyContent: {
                    img: '/escape/scenario1/lock1.png',
                },
            },
            className: `${styles.scenario_lock_1} ${styles.locks}`,
            onClickOpen: {
                type: 'image',
                img: '/escape/scenario1/reward_1.png',
            },
        },
        {
            lockId: 'scenario_1_lock_2',
            type: 'lock',
    
            onClick: {
                lockId: 'scenario_1_lock_2',
                type: 'lock',
                missionId: 1,
                redirectTo: null,
                password: '12467',
                inputType: 'number',
                
                bodyContent: {
                    img: '/escape/scenario1/lock2.png',
                },
                customInputText: 'Digite aqui os números das condutas corretas:',
            },
            className: `${styles.scenario_lock_2} ${styles.locks}`,
            onClickOpen: {
                type: 'image',
                img: '/escape/scenario1/reward_2.png',
            },
        },
        {
            lockId: 'scenario_1_lock_3',
            type: 'lock',
    
            onClick: {
                lockId: 'scenario_1_lock_3',
                type: 'lock',
                missionId: 1,
                redirectTo: {
                    type: 'game',
                    missionId: 2,
                },
                password: '8590',
                inputType: 'number',
                bodyContent: {
                    img: '/escape/scenario1/lock3.png',
                },
            },
            className: `${styles.scenario_lock_3} ${styles.locks}`,
        },
        {
            type: 'image',
            onClick: {
                type: 'image',
                img: '/escape/scenario1/escape1.png',
            },
            className: `${styles.scenario_image_1} ${styles.locks}`,
        },
    ],
    redirectTo: {
        type: 'game',
        missionId: 2
    }

}

