export const selectOptions7 = {
	missionId: 49,
	img: '/sicred/city2.png',
	auxImg: '/quiz/quiz01_aux.png',
    corrects: 6,
	redirectTo: {
		type: 'complaints', 
		missionId: 3,
	},
	options: [
        {
            id: 'selectOption7_1',
            text: 'Acionar a gestão ou o Canal de Denúncias, se tiver suspeitas que um parceiro, fornecedor ou associado esteja envolvido com práticas de trabalho infantil ou escravo.',
            score: 50
        },
        {
            id: 'selectOption7_2',
            text: 'Ignorar a sobrecarga de trabalho da equipe, sem promover um boa distribuição das atividades de acordo com as capacidades, habilidades e competências de cada um.',
            score: 0
        },
        {
            id: 'selectOption7_3',
            text: 'Estimular e incentivar o trabalho além da carga horária permitida e contratada.',
            score: 0
        },
        {
            id: 'selectOption7_4',
            text: 'Contratar menores de 18 anos somente na condição de Aprendiz, de acordo com a CLT. ',
            score: 50
        },
        {
            id: 'selectOption7_5',
            text: ' Avaliar a cadeia de valor de fornecedores na contratação ou na sua renovação, verificando se fazem uso de trabalho infantil ou escravo direta ou indiretamente.',
            score: 50
        },
        {
            id: 'selectOption7_6',
            text: 'Prorrogar o máximo ou por várias vezes o gozo das férias.',
            score: 0
        },
        {
            id: 'selectOption7_7',
            text: 'Valorizar os aprendizes e estagiários, promovendo uma formação e ambiente necessário para o desenvolvimento deles.',
            score: 50
        },
        {
            id: 'selectOption7_8',
            text: 'Promover ambiente emocional e social favorável para o exercício das atividades das pessoas colaboradoras.',
            score: 50
        },
        {
            id: 'selectOption7_9',
            text: 'Controlar a frequência das pessoas colaboradoras em ir ao banheiro ou usar a copa.',
            score: 0
        },
        {
            id: 'selectOption7_10',
            text: 'Oferecer as pessoas colaboradoras segurança no trabalho, por meio de ergonomia e demais recursos necessários para uma boa produtividade.',
            score: 50
        },
        {
            id: 'selectOption7_11',
            text: 'Oferecer feedbacks negativo ou chamar atenção de colega ou subordinado na presença de outros.',
            score: 0
        },
    ]
};
