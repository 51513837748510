/* eslint-disable jsx-a11y/alt-text */
import styles from './modal.module.scss';
import { CloseCircleSolid } from './CloseModal';

export default function GenericModal({
	isOpen,
	setIsOpen,
	content,
	onClick,
	closeBtnDisabled,
	className,
	setCurrentPage,
	setVideo,
	tutorial,
}) {
	if (!isOpen) return;

	function handleTutorial() {
		setVideo({
			missionId: 99999,
			type: 'video',
			videoUrl: 'https://vimeo.com/1008931835/5407e586cf?share=copy',
		});
		setCurrentPage('video');
		setIsOpen(false)
	}

	return (
		<>
			<div className={styles.backdrop}>
				<div className={`${styles.modal} ${className}`}>
					{!closeBtnDisabled && (
						<CloseCircleSolid
							onClick={() => setIsOpen(false)}
							className={styles.closeModal}
						/>
					)}

					<div className={styles.body}>{content.modalContent}</div>
					<div className={styles.wrappper}>
						<button onClick={onClick} className={styles.btn}>
							INICIAR
						</button>
						{tutorial
							? content.type === 'escape' && (
									<button
										onClick={()=>{handleTutorial()}}
										className={`${styles.btn} ${styles.tutorial}`}
									>
										TUTORIAL
									</button>
							  )
							: null}
					</div>
				</div>
			</div>
		</>
	);
}
