
export const quiz8 = {
	missionId: 25,
	img: '/sicred/city2.png',
	auxImg: '/quiz/quiz01_aux.png',
	redirectTo: {
		type: 'video',
		missionId: 26
	},
	questionTxt:
		' Durante uma ação de educação financeira em uma empresa associada, você e mais um colega de trabalho do Sicredi são convidados para um coffee break pelos sócios. Durante a conversa, o tópico de política surge e um dos sócios começa a questionar sobre preferências políticas. Consciente das diretrizes do código de conduta, o que você faz?',
	alternatives: [
		{
			id: 1,
			text: 'A - Contraria as opiniões políticas dos sócios, mas com respeito, mesmo que seja de um associado, você expressa sua opinião abertamente, sem menosprezá-lo.',
			next: null,
			redirectTo: {
				type: 'video',
				missionId: 5,
			},
			score: 0,
			correct: false
		},
		{
			id: 2,
			text: 'B -  Você opta por não discutir sobre posição político-partidária.',
			next: null,
			redirectTo: {
				type: 'video',
				missionId: 5,
			},
			score: 0,
			correct: false

		},
		{
			id: 3,
			text: 'C - Você opta por não discutir sobre posição político-partidária. Aguarda ate que a conversa sobre esse assunto cesse e logo após conversa para os serviços financeiros e os benefícios que o Sicredi oferece, mantendo a interação profissional e focada nos negócios.',
			next: null,
			redirectTo: {
				type: 'video',
				missionId: 5,
			},
			score: 0,
			correct: false

		},
		{
			id: 4,
			text: 'D - Você opta por não discutir sobre posição político-partidária, candidatos ou partidos. Em vez disso, redireciona a conversa para os serviços financeiros e os benefícios que o Sicredi oferece, mantendo a interação profissional e focada nos negócios.',
			next: null,
			redirectTo: {
				type: 'video',
				missionId: 5,
			},
			score: 100,
			correct: true

		},
	],
};
