import styles from './escape3.module.scss';

export const escapeLive3 = {
	missionId: 8,
	bgImage: '/escape/scenario3/bg.png',
	interestingPoints: [
		{
			type: 'image',
			onClick: {
				type: 'image',
				img: '/escape/scenario3/escape1.jpg',
			},
			className: `${styles.scenario_image_1} ${styles.locks}`,
		},
		{
			lockId: 'scenario_3_lock_1',
			type: 'lock',
			onClick: {
				lockId: 'scenario_3_lock_1',
				redirectTo: null,
				type: 'lock',
				password: '31',
				inputType: 'number',
				customInputText: 'Combine a resposta das duas imagens para formar a senha:',
				bodyContent: {
					img: ['/escape/scenario3/lock1_1.jpg', '/escape/scenario3/lock1_2.jpg'],
				},
			},
			className: `${styles.scenario_lock_1} ${styles.locks}`,
			onClickOpen: {
				type: 'image',
				img: '/escape/scenario3/reward_1.png',
			},
		},
		{
			lockId: 'scenario_3_lock_2',
			type: 'lock',

			onClick: {
				lockId: 'scenario_3_lock_2',
				type: 'lock',
				missionId: 8,
				redirectTo: null,
				password: '3946',
				inputType: 'number',
				bodyContent: {
					img: '/escape/scenario3/lock2.jpg',
				},
			},
			className: `${styles.scenario_lock_2} ${styles.locks}`,
			onClickOpen: {
				type: 'image',
				img: '/escape/scenario3/reward_2.jpg',
			},
		},
		{
			lockId: 'scenario_3_lock_3',
			type: 'lock',

			onClick: {
				lockId: 'scenario_3_lock_3',
				type: 'lock',
				missionId: 8,
				redirectTo: {
					type: 'game',
					missionId: 9,
				},
				password: '4367',
				inputType: 'number',
				bodyContent: {
					img: '/escape/scenario3/lock3.jpg',
				},
			},
			className: `${styles.scenario_lock_3} ${styles.locks}`,
		},
	],
	redirectTo: {
		type: 'game',
		missionId: 9,
	},
};
