import styles from './activeAccount.module.scss';
import { useState } from 'react';
import { getApiClient } from 'src/services/axios';
import showToast from 'src/components/utils/toast';

export default function ActiveAccount() {
	const [token, setToken] = useState('');
	const [email, setEmail] = useState('');

    function backToMain(){
        window.location.href = `/`
    }

    const handleSubmit = async () => {
		try {
			const response = await getApiClient().post('/active-account', {
				email: email.toLocaleLowerCase(),
				token: token,
			});
			if (response.status === 200) {
				showToast({
					text: 'Usuário ativo com sucesso, você será redirecionado para tela de login',
					type: 'success',
				});
				setTimeout(() => {
					window.location.href = `/`;
				}, 2000);
				return;
			}
			showToast({
				text: 'Erro ao ativar usuário',
				type: 'error',
			});
		} catch (error) {
			showToast({ text: 'Erro ao ativar usuário', type: 'error' });
		}
	};
	return (
		<div className={styles.activeAccount}>
			<form className={styles.form}>
				<img
					src="/reinodaetica/reinodaetica.png"
					alt="Reino da Ética"
				/>
				<label htmlFor="email" className={styles.label}>
					Email:
				</label>
				<input
					id="email"
					name="email"
					type="email"
                    placeholder='Preencha com seu email'
					onChange={(e)=>setEmail(e.target.value)}
					className={styles.input}
				></input>

				<div>
					<label htmlFor="token" className={styles.label}>
						Token:
					</label>
					<input
						id="token"
						name="token"
						type="token"
						onChange={(e) => setToken(e.target.value)}
						className={styles.input}
					/>
					<button type="button" onClick={()=>handleSubmit()} className={styles.button}>
						Ativar conta
					</button>
					<button type="button" onClick={()=> backToMain()} className={`${styles.button} ${styles.back}`}>
						Voltar
					</button>
				</div>
			</form>
		</div>
	);
}
