export const quiz14 = {
	missionId: 46,
	img: '/sicred/city2.png',
	auxImg: '/quiz/quiz01_aux.png',
	redirectTo: {
		type: 'video',
		missionId: 47
	},
	questionTxt:
		'Na semana que antecede as eleições, você ganha várias canetas e blocos de notas de um partido político e pensa que será ótimo compartilhar com as demais pessoas colaboradoras para economizar os materiais de expediente. O que você faz?',
	alternatives: [
		{
			id: 1,
			text: 'A - Levo os materiais e distribuo para as pessoas colaboradoras mais íntimas, lembrando para eles que não devem usar os materiais nas dependências do Sicredi.',
			next: null,
			redirectTo: {
				type: 'video',
				missionId: 5,
			},
			score: 0,
			correct: false
		},
		{
			id: 2,
			text: 'B - Levo os materiais para minha sala e deixo em cima da minha mesa para ao final do expediente leva-los para casa.',
			next: null,
			redirectTo: {
				type: 'video',
				missionId: 5,
			},
			score: 0,
			correct: false

		},
		{
			id: 3,
			text: 'C - Deixo alguns materiais, como canetas, para eu usar durante o meu serviço, como esta somente na minha sala, não vai ter problema.',
			next: null,
			redirectTo: {
				type: 'video',
				missionId: 5,
			},
			score: 0,
			correct: false

		},
		{
			id: 4,
			text: 'D -  Não leva os materiais para a empresa. A intenção é boa, mas não devem ser distribuídos materiais e brindes de partidos políticos nas dependências do Sicredi.',
			next: null,
			redirectTo: {
				type: 'game',
				missionId: 31,
			},
			score: 100,
			correct: true
		},
	],
};
