import styles from './escape5.module.scss';

export const escapeLive5 = {
	missionId: 15, /// mudar em baixo tambem
	bgImage: '/escape/scenario5/bg.png',
	interestingPoints: [
		{
			type: 'image',
			onClick: {
				type: 'image',
				img: '/escape/scenario5/escape1.png',
			},
			className: `${styles.scenario_image_1} ${styles.locks}`,
		},
		{
			lockId: 'scenario_5_lock_1',
			type: 'lock',
			onClick: {
				lockId: 'scenario_5_lock_1',
				redirectTo: null,
				type: 'lock',
				password: '359',
				inputType: 'number',
				bodyContent: {
					img: '/escape/scenario5/lock1.png',
				},
			},
			className: `${styles.scenario_lock_1} ${styles.locks}`,
			onClickOpen: {
				type: 'image',
				img: '/escape/scenario5/reward_1.png',
			},
		},
		{
			lockId: 'scenario_5_lock_2',
			type: 'lock',

			onClick: {
				lockId: 'scenario_5_lock_2',
				type: 'lock',
				missionId: 1,
				redirectTo: null,
				password: '42895',
				inputType: 'number',
				bodyContent: {
					img: '/escape/scenario5/lock2.png',
				},
			},
			className: `${styles.scenario_lock_2} ${styles.locks}`,
			onClickOpen: {
				type: 'image',
				img: '/escape/scenario5/reward_2.png',
			},
		},
		{
			lockId: 'scenario_5_lock_3',
			type: 'lock',

			onClick: {
				lockId: 'scenario_5_lock_3',
				type: 'lock',
				missionId: 15,
				redirectTo: {
					type: 'game',
					missionId: 16,
				},
				password: 'ETICA',
				inputType: 'text',
				bodyContent: {
					img: '/escape/scenario5/lock3.png',
				},
			},
			className: `${styles.scenario_lock_3} ${styles.locks}`,
		},
	],
	redirectTo: {
		type: 'game',
		missionId: 16, // mudar acima tambem
	},
};
