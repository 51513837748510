export const selectOptions1 = {
	missionId: 7,
	img: '/sicred/city2.png',
	auxImg: '/quiz/quiz01_aux.png',
    corrects: 6,
	redirectTo: {
		type: 'complaints',
		missionId: 3,
	}, 
	options: [
        {
            id: 'selectOption1_1',
            text: 'Atender os normativos oficiais emitidos pelos órgãos que regulam as instituições financeiras, a fim de não comprometer a imagem e reputação do Sicredi.',
            score: 50
        },
        {
            id: 'selectOption1_2',
            text: 'Repostar informações do Sicredi nas redes sociais (LinkedIn, Instagram, Facebook, Twitter), somente aquelas disponibilizadas nos perfis oficiais da instituição.',
            score: 50
        },
        {
            id: 'selectOption1_3',
            text: 'Compartilhar com gestores ou responsáveis quando identificar alguma divulgação indevida ou que contenha erro, seja nos materiais internos ou externos, direcionando a correção.',
            score: 50
        },
        
        {
            id: 'selectOption1_4',
            text: 'Participar de manifestações políticas vestindo uniforme ou algo que faça alusão à marca do Sicredi.',
            score: 0
        },
        {
            id: 'selectOption1_5',
            text: 'Usar o crachá da empresa fora das dependências do Sicredi, sem estar a trabalho.',
            score: 0
        },
  
        {
            id: 'selectOption1_6',
            text: 'Postar nas redes sociais fotos e/ou opiniões pessoais fazendo uso ou referência a imagem e marca do Sistema.',
            score: 0
        },
        {
            id: 'selectOption1_7',
            text: 'Deixar de atender um normativo oficial externo sem o consentimento das áreas competentes.',
            score: 0
        },
        {
            id: 'selectOption1_8',
            text: 'Pedir um presente ou favor, para facilitar as condições de empréstimo de um associado.',
            score: 0
        },
        {
            id: 'selectOption1_9',
            text: 'Deixar de realizar os procedimento obrigatórios nas liberações de crédito, financiamentos, abertura e cadastros de conta, e nas rotinas de prevenção à lavagem de dinheiro.',
            score: 0
        },
        {
            id: 'selectOption1_10',
            text: 'Comunicar-se de uma forma clara e objetiva, buscando a compreensão e a aproximação com o público alvo.',
            score: 50
        },
        {
            id: 'selectOption1_11',
            text: 'Observar os normativos internos e externos antes da criação de novos produtos, negócios, processos e marcas.',
            score: 50
        },
       
        {
            id: 'selectOption1_12',
            text: 'Reportar à gestão ou ao Canal de Denúncias , qualquer atividade suspeita de prática de lavagem de dinheiro ou financiamento ao terrorismo.',
            score: 50
        },
    ]
};
