/* eslint-disable jsx-a11y/alt-text */
import styles from './escapeModal.module.scss';
import { CloseCircleSolid } from './CloseModal';
import { useEffect, useState } from 'react';
import { getApiClient } from 'src/services/axios';
import { useParams } from 'react-router-dom';
import showToast from 'src/components/utils/toast';
import { queryClient } from 'src/services/queryClient';
import moment from 'moment-timezone';
import { ArrowBackIos, ArrowForwardIos } from '@mui/icons-material';
import Switch from 'react-switch';
import Zoom from 'react-img-zoom';

export function BtnClue({ lockId, setLockId, setIsOpenClue }) {
	function openClueModal() {
		setLockId(lockId);
		setIsOpenClue(true);
	}
	return (
		<div>
			<button
				className={`${styles.btn} ${styles.clue}`}
				onClick={() => openClueModal()}
			>
				Solicitar Dica
			</button>
		</div>
	);
}

export default function EscapeModal({
	isOpen,
	setIsOpen,
	content,
	setPage,
	setUnlockByTime,
	setOpenGenericModal,
	missionId,
	setLockId,
	setIsOpenClue,
}) {
	const [password, setPassword] = useState('');
	const { playerId } = useParams();
	const [switchStates, setSwitchStates] = useState(
		content.switchs ? content.switchs.map(() => false) : []
	);

	useEffect(() => {
		setSwitchStates(
			content.switchs ? content.switchs.map(() => false) : []
		);
	}, [content]);

	async function handleSubmit() {
		const token = localStorage.getItem('SUT');

		await getApiClient(token).post(`/updateEscapeReport/${playerId}`, {
			escapeLiveIds: [],
			locks: [content.lockId],
			startedTimes: [],
			endedTimes: [],
		});
		queryClient.invalidateQueries({
			queryKey: [`escapeLiveReport${missionId}`],
		});
		let redirect = content.redirectTo ? content.redirectTo : null;
		const endedTime = moment.tz(new Date(), 'America/Sao_Paulo').toDate();
		showToast({ text: 'Cadeado aberto', type: 'success' });

		setPassword('');

		if (redirect) {
			try {
				await getApiClient(token).post(
					`/updateEscapeReport/${playerId}`,
					{
						escapeLiveIds: [content.missionId],
						locks: [],
						startedTimes: [],
						endedTimes: [
							{
								escapeLiveId: content.missionId,
								timeEnded: endedTime,
								escape: true,
							},
						],
					}
				);
				setOpenGenericModal(true);
				setUnlockByTime(false);
			} catch (error) {
				console.log(error);
			}
		}
		setIsOpen(false);
	}

	function handleCloseModal() {
		setPassword('');
		setIsOpen(false);
	}

	function handleBackdropClick(e) {
		if (e.target === e.currentTarget) {
			handleCloseModal();
		}
	}

	if (!isOpen) return null;
	return (
		<>
			<div
				className={styles.backdrop}
				onClick={handleBackdropClick}
			></div>

			<div className={styles.modal}>
				<CloseCircleSolid
					onClick={() => handleCloseModal()}
					className={`${styles.closeModal} ${
						content.type === 'image' && styles.black
					}`}
				/>

				{content.type === 'switch' && switchStates !== null && (
					<SwitchComponent
						content={content}
						setIsOpenClue={setIsOpenClue}
						switchStates={switchStates}
						setLockId={setLockId}
						setSwitchStates={setSwitchStates}
						handleSubmit={handleSubmit}
					/>
				)}

				{content.type === 'lock' && (
					<LockComponent
						content={content}
						setIsOpenClue={setIsOpenClue}
						setLockId={setLockId}
						setPassword={setPassword}
						handleSubmit={handleSubmit}
						password={password}
					/>
				)}
				{content.type === 'image' && (
					<div className={styles.wrapperImage}>
						{Array.isArray(content.img) ? (
							<Carrousel
								className={styles}
								arrayImages={content.img}
							/>
						) : (
							<div className={styles.image}>
								<Zoom
									img={content.img}
									zoomScale={2}
									width={600}
									height={600}
								/>
							</div>
						)}
					</div>
				)}
			</div>
		</>
	);
}

function SwitchComponent({
	content,
	handleSubmit,
	switchStates,
	setIsOpenClue,
	setLockId,
	setSwitchStates,
}) {
	function handleSwitchChange(index) {
		const updatedSwitchStates = switchStates.map((state, i) =>
			i === index ? !state : state
		);
		setSwitchStates(updatedSwitchStates);
	}

	async function submit() {
		try {
			if (
				!Array.isArray(switchStates) ||
				!Array.isArray(content.switchs)
			) {
				throw new Error(
					'Os arrays switchStates ou content.switchs não estão definidos corretamente.'
				);
			}

			const isSwitchStateValid =
				switchStates.length === content.switchs.length &&
				switchStates.every(
					(state, index) => state === content.switchs[index].on
				);

			if (!isSwitchStateValid) {
				showToast({
					text: 'Senha incorreta',
					type: 'error',
				});
				return;
			}
			await handleSubmit();
		} catch (error) {
			console.error('Erro ao validar os estados dos switches:', error);
			return;
		}
	}
	return (
		<div className={styles.switchWrapper}>
			<div className={styles.imgWrapper}>
				<img className={styles.image} src={content.bodyContent.img} />
			</div>
			<div className={styles.switchs}>
				<BtnClue
					lockId={content.lockId}
					setIsOpenClue={setIsOpenClue}
					setLockId={setLockId}
				/>
				<div
					className={`${styles.switchWrapper} ${
						content.customSwitchStyle && content.customSwitchStyle
					}`}
				>
					{content.switchs.map((switchItem, index) => (
						<div className={styles.switch} key={index}>
							{switchItem.header && switchItem.header}
							<Switch
								height={20}
								width={40}
								onColor={
									switchItem.customOnSwitchColor &&
									switchItem.customOnSwitchColor
								}
								className={
									switchItem.switchStyle &&
									switchItem.switchStyle
								}
								onChange={() => handleSwitchChange(index)}
								checked={switchStates[index]}
							/>
							{switchItem.footer && switchItem.footer}
						</div>
					))}
				</div>
				<button className={styles.btn} onClick={() => submit()}>
					Enviar
				</button>
			</div>
		</div>
	);
}

function LockComponent({
	content,
	setIsOpenClue,
	setLockId,
	password,
	setPassword,
	handleSubmit,
}) {
	async function submit() {
		if (password !== content.password) {
			showToast({ text: 'Senha inválida', type: 'error' });
			return;
		}
		await handleSubmit();
	}
	return (
		<div className={styles.lock}>
			<div className={styles.imgWrapper}>
				{Array.isArray(content.bodyContent.img) ? (
					<Carrousel arrayImages={content.bodyContent.img} />
				) : (
					<img
						className={styles.image}
						src={content.bodyContent.img}
					/>
				)}
			</div>
			<div className={styles.contentWrapper}>
				<BtnClue
					lockId={content.lockId}
					setIsOpenClue={setIsOpenClue}
					setLockId={setLockId}
				/>
				<div className={styles.upsideDiv}></div>
				<div className={styles.bottom}>
					<div className={styles.inputWrapper}>
						<span>
							{content.customInputText
								? content.customInputText
								: `Senha de ${content.password.length} ${
										content.password.length > 1
											? 'digitos'
											: 'digito'
								  } `}
						</span>
						<input
							value={password}
							onChange={(e) => {
								let inputValue = e.target.value;
								if (content.inputType === 'text') {
									inputValue = inputValue
										.normalize('NFD')
										.replace(/[\u0300-\u036f]/g, '')
										.toUpperCase();
								}
								if (
									inputValue.length <= content.password.length
								) {
									setPassword(inputValue);
								} else {
									setPassword(password);
								}
							}}
							type={content.inputType}
							maxLength={content.password.length}
						/>
					</div>
					<button className={styles.btn} onClick={() => submit()}>
						Enviar
					</button>
				</div>
			</div>
		</div>
	);
}

function Carrousel({ arrayImages }) {
	const [actualImage, setActualImage] = useState(0);

	function handleBackward() {
		if (actualImage > 0) {
			setActualImage((prev) => prev - 1);
		}
	}

	function handleFoward() {
		if (actualImage < arrayImages.length - 1) {
			setActualImage((prev) => prev + 1);
		}
	}
	return (
		<div className={styles.carosselWrapper}>
			<ArrowBackIos
				onClick={() => handleBackward()}
				className={`${styles.back} ${
					actualImage !== arrayImages.length - 1 && styles.invisible
				}`}
			/>
			<img className={styles.img} src={arrayImages[actualImage]}></img>
			<h1 className={styles.imgNumber}>{actualImage + 1}</h1>
			<ArrowForwardIos
				onClick={() => handleFoward()}
				className={`${styles.foward} ${
					actualImage !== 0 && styles.invisible
				}`}
			/>
		</div>
	);
}
