export const selectOptions3 = {
	missionId: 21,
	img: '/sicred/city2.png',
	auxImg: '/quiz/quiz01_aux.png',
    corrects: 6,
	redirectTo: {
		type: 'complaints', 
		missionId: 3,
	},
	options: [
        {
            id: 'selectOption3_1',
            text: 'Não pedir brindes para fornecedores a fim de realizar sorteios entre as pessoas colaboradoras.',
            score: 50
        },
        {
            id: 'selectOption3_2',
            text: 'Convencer o associado a fazer negócios e investimentos com uma empresas de parente ou outro conhecido seu.',
            score: 0
        },
        {
            id: 'selectOption3_3',
            text: 'Exercer atividades profissionais cuja natureza conflite com os negócios do Sicredi, por exemplo, vender seguros e consórcios.',
            score: 0
        },
        {
            id: 'selectOption3_4',
            text: 'Indicar parente para vaga na sua equipe, sem que o parentesco de vocês seja de conhecimento da equipe e dos envolvidos no processo seletivo.',
            score: 0
        },
        {
            id: 'selectOption3_5',
            text: 'Entender que a carteira de associados do Sicredi é da instituição e não da pessoa colaboradora.',
            score: 50
        },
        {
            id: 'selectOption3_6',
            text: 'Recusar, educadamente, cesta de final de ano enviada pelo fornecedor, que tenha bebida alcoólica e outros produtos que somam mais de um terço do salário mínimo.',
            score: 50
        },
        {
            id: 'selectOption3_7',
            text: 'Aceitar sem consentimento do seu gestor, convite para evento que está sendo patrocinado pelo fornecedor do Sicredi.',
            score: 0
        },
        {
            id: 'selectOption3_8',
            text: 'Comunicar o gestor e Gestão de Pessoas, que seu cônjuge, filho(a), pai, mãe, familiar de primeiro grau está participando de um processo seletivo no Sicredi, se na mesma entidade.',
            score: 50
        },
        {
            id: 'selectOption3_9',
            text: 'Comunicar a gestão que começou realizar uma outra atividade profissional secundária.',
            score: 50
        },
        {
            id: 'selectOption3_10',
            text: 'Comunicar a gestão quando a empresa de algum parente estiver participando de concorrência para se tornar fornecedor do Sicredi.',
            score: 50
        },
        {
            id: 'selectOption3_11',
            text: 'Exercer outras atividades profissionais dentro do horário contratado com o Sicredi, como corrigir provas de alunos da atividade secundária de professor.',
            score: 0
        },
    ]
};
